import React from 'react';
import { FormattedNumber , injectIntl , FormattedMessage, intlShape} from 'react-intl';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';
import RadioButton from '../controls/RadioButtonComponent.js';
import DynamicBoundsAnnualMilage from '../DynamicBoundsAnnualMilage.js';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import * as Recalc from './../../services/RecalculateService.js';
import axios from 'axios';
import FileSaver from 'file-saver';
import _ from 'lodash';
import '../../styles/CarOfferModal.scss';


class CarOfferForm extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
          formIsClean: false,
          showInfoOverlay: false,
          factoryFittedEquipmentExpense: 0,
          retroFittedEquipmentExpense: 0,
          informationOverlayText: '',
          selectedColor: 0,
          form: {
            name: '',
            company: '',
            phone: '',
            email: '',
            errors: {
              name: false,
              company: false,
              phone: false,
              email: false
            }
          },
          milage: 5000,
          nyekm: 15000,
          fuelCardList: [],
          checkedItems: [],
          checkedReadioButtons: [{
                groupName: 'insurance',
                radioButton: 'lowRisk'
              }, { groupName: 'leasingPeriod', radioButton: 'radioButton36' }],
          radioButtonIsNotDefault: false,
          nyeperiode: 37,
          thechosenone: {
            chosenCar : {
                Taxation : 0,
                MonthlyPrice : 0
            },
            taxationMonthly: 0,
            isFirstLoad : true
          }
        }
    }

    showGDPR() {
      let language = navigator.language || navigator.userLanguage;
      if (language.indexOf('da') > -1) {
        window.open('https://nordania.dk/erhverv/diverse/persondatapolitik', "_blank");
      } else {
        window.open('https://nordania.dk/erhverv/diverse/persondatapolitik-en', "_blank");
      }
    }

    sendComplete(){

        this.validate();
        this.setState({});

        if (this.state.formIsClean){
          this.sendOrderSummary(true, true);
          this.props.closeModal();
          this.context.router.push('/done')
        }        
    }

    sendOrderSummary(isMail, complete) {

        var filters = {
          carid: this.state.thechosenone.chosenCar.CarlistId,
          customerWishList: null,
          nyKm: this.props.car.nyekm,
          nyeperiode: this.props.car.nyeperiode,
          afgiftUdstyr: null,
          monthlyPrice: this.state.thechosenone.chosenCar.MonthlyPrice,
          taxation: this.state.thechosenone.chosenCar.Taxation,
          color: null,
          taxationMonthly: this.state.thechosenone.taxationMonthly,
          selectedEquipmentList: [],
          extraordinary: this.state.udbetaling,
          mail: this.state.form.email,
          isMail: isMail,
          fuelCardList: null,
          lang: this.props.intl.locale,  //??? 
          insurance: null,
          nettomoms: this.state.nettomoms,
          momsfradrag: this.state.momsfradrag,
          calculationvalues: this.state.calculationValues, // ???
          completeOrder: complete,
          name: this.state.form.name,
          nyeperiode: this.state.nyeperiode,
          company: this.state.form.company,
          phone: this.state.form.phone,
          email: this.state.form.email,
          leasingChoises: [],
          leasingSpecifications: [],
          nyKm: this.state.nyekm
        }
    
        var url = this.context.baseurl + '/api/blue/v1/summary';
    
        axios.post(url, filters, { responseType: 'arraybuffer', headers: { 'TestTest': 'asd' } })
          .then(function (response, err) {
            if (response) {
              if (!isMail) {
                var blob = new Blob([response.data], { type: 'application/pdf;charset=utf-8' });
                FileSaver.saveAs(blob, 'NordaniaBlue.pdf');
              } else { console.log('Summary sent to email') }
            } else if (err) {
              alert(err);
            }
          }).catch(function (error) {
            alert(error);
          });
      }

    renderDefaultRadioButton() {
        var isDefault = (this.state.checkedReadioButtons || this.state.checkedReadioButtons.length) == 0;
        return <RadioButton
          isDefault={isDefault}
          processRadioButtons={this.processRadioButtons.bind(this)}
          id='radioButton36'
          groupName='leasingPeriod'
          labelText='36'
          checkedReadioButtons={this.state.checkedReadioButtons}
        />
      }

      processRadioButtons(radioButton, name, radioButtonValue) {
        _.remove(this.state.checkedReadioButtons, x => x.groupName == name);
        // This means that the nyKm has been changed
        if (name == 'leasingPeriod') {
          var newPeriod = parseInt(radioButtonValue);
          this.state.radioButtonIsNotDefault = true;
          this.state.nyeperiode = newPeriod + 1;
          this.GetChosenCarData();
        }

        if (name == 'insurance') {
            this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
          }
      
          var result = {
            groupName: name,
            radioButton: radioButton
          }
      
          this.state.checkedReadioButtons.push(result);
          this.setState({
            checkedReadioButtons: this.state.checkedReadioButtons
          });
        }

        calculateNewMonthlyPrice(factoryFittedExpense, retroExpense) {

          var assistance = false;
          var winterTires = false;
          var ekstra = false;
          var insurance = 0;
          var noOfFuelCards = this.state.fuelCardList.length;
      
          var self = this;
          Recalc.calculateNewMonthlyPrice(
            //factoryFittedExpense, retroExpense,
            this.state.selectedEquipmentList.join('-'),
            this.context.baseurl,
            this.state.thechosenone.chosenCar.RegType,
            this.state.thechosenone.chosenBlue.ForudsatVarditab,
            this.state.nyeperiode,
            this.state.nyekm,
            this.state.thechosenone.chosenBlue.FraRegafgift,
            this.state.thechosenone.chosenCar.Pv,
            this.state.thechosenone.chosenBlue.Fv,
            this.state.thechosenone.chosenCar.Oblextraeq,
            this.state.thechosenone.chosenBlue.RvSats,
            this.state.thechosenone.chosenCar.Taxation,
            this.state.thechosenone.chosenCar.Miljofee,
            this.state.thechosenone.chosenBlue.WheelPris,
            this.state.thechosenone.chosenBlue.Service,
            assistance,
            winterTires,
            ekstra,
            this.state.thechosenone.chosenBlue.WheelWinter,
            this.state.thechosenone.chosenCar.CarFee,
            insurance,
            this.state.thechosenone.chosenBlue.Funding,
            noOfFuelCards,
            this.state.selectedColor,
            this.state.thechosenone.chosenCar.CarlistId
          ).then(function (response, err) {
            if (response) {
              self.state.nettomoms = response.data.nettomoms;
              self.state.momsfradragsats = response.data.momsfradragsats;
              self.state.fuldafgift = response.data.fuldafgift;
              self.state.momsfradrag = response.data.momsfradrag;
              self.state.momsgrundlag = response.data.momsgrundlag;
              self.state.afgiftEquipment = response.data.afgiftEquipment;
              self.state.thechosenone.chosenCar.MonthlyPrice = response.data.monthlyFeeTotal;
              self.state.thechosenone.chosenCar.Taxation = response.data.taxationtotal;
              self.state.processedFactoryFittedEquipmentExpenses = response.data.priceWithExpenses;
              self.state.thechosenone.taxationMonthly = response.data.taxationMonthly;
              self.state.insurancePrice = response.data.insurancePrice;
              self.state.udbetaling = response.data.udbetaling;
              self.state.calculationValues = response.data.calculationData;
              self.state.varnishPrice = response.data.varnishPrice;
              self.state.varnishName = response.data.varnishName == '' ? '' : ' (' + response.data.varnishName + ')';
              self.forceUpdate();
            } else if (err) {
              alert(err);
            }
          }).catch(function (error) {
            alert(error);
          });
        }

    parseBackFormState() {
        if (this.state.form.errors.name === false && this.state.form.name !== '' &&
          this.state.form.errors.company === false && this.state.form.company !== '' &&
          this.state.form.errors.phone === false && this.state.form.phone !== '' &&
          this.state.form.errors.email === false && this.state.form.email !== '') {
          this.setState({ formIsClean: true });
        } else {
          this.setState({ formIsClean: false });
        }
      }

      nameChange(e) {
        this.state.form.name = e.target.value;
        this.validateName(e.target.value);
        this.parseBackFormState();
        this.forceUpdate();
      }

      checkForChars(chars, stringToCheck) {
        for (var i = 0; i < chars.length; i++) {
          if (stringToCheck.indexOf(chars[i]) > -1) {
           return true
          }
        }
        return false;
      }
    
      validateName(nameText){
        if (nameText === '' || this.checkForChars(':\\.[]', nameText)) {
          this.state.form.errors.name = true
        } else {
          this.state.form.errors.name = false
        }
      }
      companyChange(e) {
        this.state.form.company = e.target.value;
        this.validateCompany(e.target.value);
        this.parseBackFormState();
        this.forceUpdate();
      }
    
      validateCompany(companyText){
        if (companyText === '' || this.checkForChars(':\\.[]', companyText)) {
          this.state.form.errors.company = true
        } else {
          this.state.form.errors.company = false
        }
      }
    
      phoneChange(e) {
        this.state.form.phone = e.target.value;
        this.validatePhone(e.target.value);
        this.parseBackFormState();
        this.forceUpdate();
      }
    
      validatePhone(phoneText){
        if (/^\d+$/.test(phoneText)) {
          this.state.form.errors.phone = false
        } else {
          this.state.form.errors.phone = true
        }
      }
    
      emailChange(e) {
        this.state.form.email = e.target.value;
        this.validateEmail(e.target.value);
        this.parseBackFormState();
        this.forceUpdate();
      }
    
      validateEmail(emailText){
        if (/^(.+)@(.+){2,}\.(.+){2,}$/.test(emailText)) {
          this.state.form.errors.email = false
        } else {
          this.state.form.errors.email = true;
        }
      }
    
      processMilage(milages) {
        this.setState( {
            milage : milages,
            nyekm : milages
        });
        this.state.milage = milages;
        this.state.nyekm = milages;
        this.GetChosenCarData();
        
      }

      validate(){
        this.validateName(this.state.form.name);
        this.validateCompany(this.state.form.company);
        this.validatePhone(this.state.form.phone);
        this.validateEmail(this.state.form.email);
      }

      GetChosenCarData() {
        var self = this;
        Recalc.GetChosenCarData(this.context.baseurl, this.props.car.CarlistId, this.state.nyekm, this.state.nyeperiode)
          .then(function (response, err) {
            if (response) {
                self.setState({
                    thechosenone: response.data,
                    milage: response.data.chosenBlue.NyKm
                });
                self.calculateNewMonthlyPrice(self.state.factoryFittedEquipmentExpense, self.state.retroFittedEquipmentExpense);
            } else if (err) {
              alert(err);
            }
          }).catch(function (error) {
            alert(error);
          });
    }
    componentWillMount (){
        this.GetChosenCarData();
    }

    render() {
        const { formatMessage } = this.props.intl;
       
        return (
            <div className="modalform-caroffer" >
                <div className="modalform-caroffer__window">
                    <a href="#" className="modalform-caroffer__close" onClick={() => this.props.closeModal()}>
                        <CloseModalButton />
                    </a>
                    <div className="carOfferMain-component">
        <section>
          <section className="container-top">
            <section>
              <h3><FormattedMessage id={'Leasing.Leasing.LeasingPeriod'} /></h3>
              <RadioButton
                processRadioButtons={this.processRadioButtons.bind(this)}
                id='radioButton24'
                groupName='leasingPeriod'
                labelText='24'
                checkedReadioButtons={this.state.checkedReadioButtons}
              />
              {this.renderDefaultRadioButton()}
              <RadioButton
                processRadioButtons={this.processRadioButtons.bind(this)}
                id='radioButton48'
                groupName='leasingPeriod'
                labelText='48'
                checkedReadioButtons={this.state.checkedReadioButtons}
              />
              <RadioButton
                processRadioButtons={this.processRadioButtons.bind(this)}
                id='radioButton60'
                groupName='leasingPeriod'
                labelText='60'
                checkedReadioButtons={this.state.checkedReadioButtons}
              />
            </section>
            <section>
              <h3>
                <FormattedMessage id={'Leasing.Leasing.AnnualMilage'} /> {this.state.milage}
              </h3>
              <DynamicBoundsAnnualMilage
                milage={this.state.milage}
                processMilage={this.processMilage.bind(this)}
              />
            </section>            
          </section>
          <section className="container-bottom">
          <div className="inputFields">
                <div className="input">
                  <form>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.name ? ' errorForm' : '')} onChange={this.nameChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.NamePlaceholder' })} value={this.state.form.name} />
                    </div>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.company ? ' errorForm' : '')} onChange={this.companyChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.CompanyPlaceholder' })} value={this.state.form.company} />
                    </div>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.phone ? ' errorForm' : '')} onChange={this.phoneChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.PhonePlaceholder' })} value={this.state.form.phone} />
                    </div>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.email ? ' errorForm' : '')} onChange={this.emailChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.EmailPlaceholder' })} value={this.state.form.email} />
                    </div>
                    <div className="info-consent">
                      <div onClick={() => {this.showGDPR()}} className="icon"></div>
                      <div className="message">{formatMessage({ id: 'ContentCheckout.Consent' })}</div>
                    </div>
                  </form>
                </div>
          </div>
          <div className='carInfoForm'>
                <span><h1>{this.state.thechosenone.chosenCar.Brand} {this.state.thechosenone.chosenCar.Model}</h1><div className={this.state.mobilePriceArrowClass} id='priceDropDown'></div></span>
                <span><p>{this.state.thechosenone.chosenCar.Description1}</p><div className="titlePrice"></div></span>
                 
                <table>
                    <tr>
                        <td><span>{formatMessage({ id: 'CarOverview.TaxationTotal'})}</span></td>
                        <td><FormattedNumber value={this.state.thechosenone.chosenCar.Taxation} />&nbsp;kr.</td>
                   </tr>
                   <tr>
                       <td>{formatMessage({ id: 'CarOverview.TaxationMonthly' })}&nbsp;<span className="small-text">{formatMessage({ id: 'CarOverview.EnvironmentalExtraCharge' })}</span></td>
                       <td><FormattedNumber value={this.state.thechosenone.taxationMonthly} />&nbsp;kr.</td>
                   </tr>
                   <tr>
                       <td>{formatMessage({ id: 'CarOverview.MonthlyFee' })}</td>
                       <td><FormattedNumber value={this.state.thechosenone.chosenCar.MonthlyPrice} />&nbsp;kr.</td>
                   </tr>
                 </table>
          </div>
          </section>
          <div className="offersButton" onClick={this.sendComplete.bind(this)} >
                 <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.RequestOffer' })} disabled={!this.state.formIsClean} />
          </div>
        </section>
      </div>
</div>
 </div>
        );
    }
}

CarOfferForm.propTypes = {
    intl: intlShape.isRequired
  };
  
  CarOfferForm.contextTypes = {
    baseurl: React.PropTypes.string,
    router: React.PropTypes.object.isRequired,
    uiStore: React.PropTypes.object.isRequired
  };

export default injectIntl(CarOfferForm);
