'use strict';

import React from 'react';
import _ from 'lodash'
import '../../styles/controls/RadioButton.scss';

class RadioButtonComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    }
  }

  checkChanged(e) {
    if (this.props.processRadioButtons) {
      this.props.processRadioButtons(e.target.id, e.target.name, this.props.labelText);

      /*if (e.target.name == 'insurance' && this.props.disableShowLowRiskAsDefault) {
        this.props.disableShowLowRiskAsDefault();
      }*/
    }
  }

  render() {
    if (this.props.groupName == 'insurance') {
      var value = _.find(this.props.checkedItems, x => x == 'accepInsurance');

      if (!value) {
        this.state.isDisabled = true;
      } else {
        this.state.isDisabled = false;
      }
    }

    var checked = false;

    if (this.props.checkedReadioButtons.length) {
      var res = _.find(this.props.checkedReadioButtons, x => x.radioButton == this.props.id);

      if (res) {
        checked = true;
      }
    }

    return <div className="radiobutton-component">
      {this.renderRadioBox(checked, this.state.isDisabled)}
    </div>

  }

  renderRadioBox(isChecked) {
    if (this.props.isDefault) {
      this.props.processRadioButtons(this.props.id, this.props.groupName, this.props.labelText);

      return <label>
        <input
          checked = {isChecked}
          disabled={this.state.isDisabled}
          type='radio'
          name={this.props.groupName} value=''
          onChange={this.checkChanged.bind(this)}
          id={this.props.id}
          />
        {this.props.labelText}
      </label>
    }
    else if (isChecked) {
      return <label>
        <input
          checked = {isChecked}
          disabled={this.state.isDisabled}
          type='radio'
          name={this.props.groupName} value=''
          onChange={this.checkChanged.bind(this)}
          id={this.props.id}
          />
        {this.props.labelText}
      </label>

    }
    else if (this.props.showLowRiskAsDefault && !this.state.isDisabled) {
      return <label>
        <input
          checked = {isChecked}
          disabled={this.state.isDisabled}
          type='radio'
          name={this.props.groupName} value=''
          onChange={this.checkChanged.bind(this)}
          id={this.props.id}
          />
        {this.props.labelText}
      </label>
    } else {
      return <label>
        <input
          checked = {isChecked}
          disabled={this.state.isDisabled}
          type='radio'
          name={this.props.groupName} value=''
          onChange={this.checkChanged.bind(this)}
          id={this.props.id}
          />
        {this.props.labelText}
      </label>
    }
  }
}

RadioButtonComponent.displayName = 'ControlsRadioButtonComponent';

// Uncomment properties you need
// RadioButtonComponent.propTypes = {};
// RadioButtonComponent.defaultProps = {};

export default RadioButtonComponent;
