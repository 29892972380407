/*global flashEventFunction */
'use strict';

import React from 'react';
import FilterListItem from './FilterListItemComponent.js';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash'
import '../../styles/FilterLevel01.scss';

class FilterBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = { countAtMount: {} };

  }
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="filterside-component">
        <a href="#" onClick={this.closeWindow.bind(this)}>
          <CloseModalButton />
        </a>
        <header>{formatMessage ({ id: 'FilterBrand.Header' })}</header>
        {this.renderFilterListItem()}
      </div>
    );
  }

  closeWindow() {
    this.props.toggleFilterWindow('none');
    if (window.nordania.trackingInitialized) {
      // Tracking
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: Close Brand and Model','','','');
    }
  }


  filterChanged(change) {

    this.props.filterChanged(change);
    if (window.nordania.trackingInitialized) {
      // Tracking
      let text = change.filter + ' - ' + change.value;
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: ' + text,'','','');
    }
    this.forceUpdate();
  }

  renderFilterListItem() {
    var self = this;

    var data = this.props.data;
    var filterListItems = [];

    _.forIn(data.facetStore.Brand, function (filterEntry, filterEntryName) {
      var checkmark = _.includes(data.state.filters.Brand, filterEntryName);

      filterListItems.push(<FilterListItem
        checkmark={checkmark}
        filterChanged={self.filterChanged.bind(self)}
        categoryName='Brand'
        filterValue={filterEntryName}
        count={filterEntry.count}
        showModelPanel={self.showModelPanel.bind(self)}
        key={filterEntry.id}
        filterName='brand'
        />);
    });
    return filterListItems;
  }
  showModelPanel(show){
    this.props.showModelPanel(show);
  }
}

FilterBrand.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterBrand);
