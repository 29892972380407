import React from 'react';
import * as BlueLocalStorage from './../helpers/localStorageHelper.js';
import { injectIntl } from 'react-intl';
import Tracking from './TrackingComponent.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import '../styles/CookieDisclaimer.scss';

class CookieDisclaimerAndTrackingComponent extends React.Component {
    componentWillMount(){
        let cdShown = BlueLocalStorage.getItem('cookieDisclaimerShown') === 'true' || false;
        let cookiesUndecided = BlueLocalStorage.getItem('cookiesAccepted') === null || false;
        let cookiesAcceptedTemp = BlueLocalStorage.getItem('cookiesAccepted') === 'true' || false;
        if (!cdShown && !cookiesUndecided) {
            cdShown = true;
        }
        this.state = { cookieDisclaimerShown: cdShown, cookiesAccepted : cookiesAcceptedTemp }
        
    }
    
    acceptCookies(){
        this.setCookieDisclaimerShown();
        BlueLocalStorage.setItem('cookiesAccepted', true);
        this.setState({cookiesAccepted : true})
    }
    
    rejectCookies(){
        this.setCookieDisclaimerShown();
        BlueLocalStorage.clearStorage();
        BlueLocalStorage.setItem('cookiesAccepted', false);
    }

    setCookieDisclaimerShown(){
        this.setState({cookieDisclaimerShown: true});
    }

    render(){
        const { formatMessage } = this.props.intl;

        return(
            <div>
            {this.state.cookiesAccepted && <Tracking />}
            <ReactCSSTransitionGroup transitionName="slide"
                transitionAppear
                transitionAppearTimeout={1000}
                transitionEnter
                transitionEnterTimeout={1000}
                transitionLeave
                transitionLeaveTimeout={1000}>
                {!this.state.cookieDisclaimerShown && <div id="cookieDisclaimer" tabIndex="0" aria-live="assertive" role="alertdialog" className="alert-box radius">
                    <section className="section section-article cookie-section">
                        <div className="section-inner">
                            <div className="row">
                                <div className="columns medium-10">
                                    <p className="cookieDisclaimerText">
                                    {formatMessage({ id: 'CookieDisclaimer.Line1' })} <br />
                                    {formatMessage({ id: 'CookieDisclaimer.Line2Start' })} <a href="#" onClick={() => this.rejectCookies()}>{formatMessage({ id: 'CookieDisclaimer.Line2RejectText' })}</a> {formatMessage({ id: 'CookieDisclaimer.Line2End' })}
                                    </p>
                                    
                                </div>
                                <div className="columns medium-2 text-right">
                                    <div className="cookieDisclaimerButton">
                                        <a href="#" onClick={() => this.acceptCookies()} className="button small" aria-label="OK">
                                            OK
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}
            </ReactCSSTransitionGroup>
    
            </div>
            
        );
    }
}

export default injectIntl(CookieDisclaimerAndTrackingComponent)