/*global flashEventFunction */
'use strict';

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import FilterTypeItem from './FilterTypeItem.js';
import '../../styles/FilterBase.scss';

class FilterBaseComponent extends React.Component {

  constructor(props) {
    
    super(props);
    this.state = {
      BodyTypeFilter: 1,
      GearFuelFilter: 2,
      TaxationFilter: 3,
      EnviromentFilter: 4,
      BrandFiler: 5,
      showFilterOnMobile: false,
      StyleClassName: 'filterbase-component'
    };
  }

  toggleMobileFilter(showFilterOnMobile) {

    this.setState({showFilterOnMobile});
    this.props.toggleMobileFilter('filter-mobile-active', showFilterOnMobile);

    if (showFilterOnMobile) {
      this.toggle('none');
    } else {
      this.closeWindow();
    }
  }

  getFilterBaseComponentClassName() {
    if (this.props.showExpandedFilter) {
      return 'filterbase-component expanded';
    } else {
      return 'filterbase-component';
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    
    return (
      <div className={this.getFilterBaseComponentClassName()} >
        <header className="expanded">
          {formatMessage({ id: 'FilterBase.Header' })}
        </header>
        <div className="filter-overlay" onClick={this.closeWindow.bind(this)}></div>
        <section className={!this.state.showFilterOnMobile ? 'hide-on-mobile' : ''}>
          <div>
            <FilterTypeItem click={() => { this.toggle(this.state.BodyTypeFilter); this.props.toggleMobileFilter('filter-mobile-active', true); }}
              filterClass='body-type' level={this.state.BodyTypeFilter} text={formatMessage({ id: 'FilterBase.BodyType' })}
              shortText={formatMessage({ id: 'FilterBase.BodyType.Short' })} />
          </div>

          <div>
            <FilterTypeItem click={() => { this.toggle(this.state.GearFuelFilter); this.props.toggleMobileFilter('filter-mobile-active', true); }}
              filterClass='gear-fuel' level={this.state.GearFuelFilter} text={formatMessage({ id: 'FilterBase.GearFuel' })}
              shortText={formatMessage({ id: 'FilterBase.GearFuel.Short' })} />
          </div>

          <div>
            <FilterTypeItem click={() => { this.toggle(this.state.TaxationFilter); this.props.toggleMobileFilter('filter-mobile-active', true); }}
              filterClass='taxation' level={this.state.TaxationFilter} text={formatMessage({ id: 'FilterBase.TaxPrice' })}
              shortText={formatMessage({ id: 'FilterBase.TaxPrice.Short' })} />
          </div>

          <div>
            <FilterTypeItem click={() => { this.toggle(this.state.EnviromentFilter); this.props.toggleMobileFilter('filter-mobile-active', true); }}
              filterClass='environmental' level={this.state.EnviromentFilter} text={formatMessage({ id: 'FilterBase.Environmental' })}
              shortText={formatMessage({ id: 'FilterBase.Environmental.Short' })} />
          </div>

          <div>
            <FilterTypeItem click={() => { this.toggle(this.state.BrandFiler); this.props.toggleMobileFilter('filter-mobile-active', true); }}
              filterClass='brand' level={this.state.BrandFiler} text={formatMessage({ id: 'FilterBase.BrandModel' })}
              shortText={formatMessage({ id: 'FilterBase.BrandModel.Short' })} />
          </div>
        </section>

        <div className="nav-bottom">
          <a className="filter-mobile-button" href="#" onClick={this.toggleMobileFilter.bind(this, !this.state.showFilterOnMobile)}>
            {formatMessage({ id: 'FilterBase.ButtonFilter' })}
          </a>
        </div>

      </div>
    );
  }

  closeWindow() {
    this.props.toggleFilterWindow('none');
  }

  toggle(level) {

    this.ResetAllClassNames();
    var res = document.getElementById(level);
    if (window.nordania.trackingInitialized) {
      var filterText = res.text;
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: ' + filterText,'','','');
    }
    if(res !== null && res !== undefined)
    {
      res.className = 'active';
    }
    this.props.toggleFilterWindow(level);
  }

  ResetAllClassNames() {

    var elemenet = document.getElementById(this.state.BodyTypeFilter);
    if (elemenet != null) {
      elemenet.className = '';
    }

    elemenet = document.getElementById(this.state.GearFuelFilter);
    if (elemenet != null) {
      elemenet.className = '';
    }

    elemenet = document.getElementById(this.state.TaxationFilter);
    if (elemenet != null) {
      elemenet.className = '';
    }

    elemenet = document.getElementById(this.state.EnviromentFilter);
    if (elemenet != null) {
      elemenet.className = '';
    }

    elemenet = document.getElementById(this.state.BrandFiler);
    if (elemenet != null) {
      elemenet.className = '';
    }

    //this.state.StyleClassName = 'filterbase-component1'
  }

  setHideOnMobile() {
    this.setState({ showFilterOnMobile: false });
  }
}


FilterBaseComponent.displayName = 'FilterBaseComponent';
FilterBaseComponent.contextTypes = {
  baseurl: React.PropTypes.string,
  uiStore: React.PropTypes.object

};
// Uncomment properties you need
// FilterBaseComponent.defaultProps = {};

FilterBaseComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterBaseComponent, { withRef: true });
