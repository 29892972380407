'use strict';

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router';
import '../../styles/CarListFavorites.scss';
import '../../styles/react-table.scss';
import ReactTable from 'react-table';


class CarListFavoritesComponent extends React.Component {
  onClick(row) {
    const transition = () => {
      this.props.router.push({
        pathname: 'details',
        query: {
          carid: row.row._original.thechosenone.chosenCar.CarlistId,
          nyekm: 15000,
          nyeperiode: 37
        }
      });
    };
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
      // HACK: there seems to be someone setting state in render!
      // this causes our state transition to be stopped (somehow).
      // We are putting in a fake delay. YUK
      // transition()
      setTimeout(transition, 200);
    } else {
      this.setState({ isTransitionInProgress: true });
      setTimeout(transition, 700);
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <ReactTable
          data={this.props.cars}
          columns={[
            {
              headerClassName: 'first',
              Header: formatMessage({ id: 'Favorites.Car' }),
              className: 'img',
              sortable: false,
              Cell: row => (
                <div
                  onClick={this.onClick.bind(this, row)}
                  style={{
                    backgroundImage:
                      'url(' + 'https://nordania.dk' + row.value + ')',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '80%',
                    width: '100%',
                    height: '100%'
                  }}
                />
              ),
              id: 'Favorites.Car',
              accessor: d => d.thechosenone.chosenCar.Imagemedium
            },
            {
              headerClassName: 'chevron',
              Header: formatMessage({ id: 'Favorites.Fuelusage' }),
              minWidth:120,
              className: 'txt',
              id: 'Favorites.Fuelusage',
              accessor: d => d.thechosenone.chosenCar.Consumption,
              Cell: row => <span>{row.value}km/l</span>
            },
            {
              headerClassName: 'chevron',
              Header: formatMessage({ id: 'Favorites.CO2' }),
              className: 'txt',
              maxWidth:80,
              id: 'thechosenone.chosenCar.CdpBodyType',
              accessor: d => d.thechosenone.chosenCar.Emissions,
              Cell: row => <span>{row.value}g/km</span>
            },
            {
              Header: formatMessage({ id: 'Favorites.Body' }),
              className: 'txt',
              maxWidth:100,
              id: 'thechosenone.chosenCar.Body',
              accessor: d => d.thechosenone.chosenCar.CdpBodyType
            },
            {
              Header: formatMessage({ id: 'Favorites.Gear' }),
              className: 'txt',
              maxWidth:100,
              id: 'thechosenone.chosenCar.Gear',
              accessor: d => d.thechosenone.chosenCar.Transmission
            },
            {
              headerClassName: 'chevron',
              Header: formatMessage({ id: 'Favorites.Effect' }),
              className: 'txt',
              id: 'thechosenone.chosenCar.Effect',
              accessor: d => d.thechosenone.chosenCar.Hk,
              Cell: row => <span>{row.value}HK</span>
            },
            {
              headerClassName: 'chevron',
              Header: formatMessage({ id: 'Favorites.Tax' }),
              className: 'txt',
              id: 'thechosenone.chosenCar.Tax',
              accessor: d => d.thechosenone.chosenCar.Taxation,
              Cell: row => <span>{row.value} kr.</span>
            },
            {
              headerClassName: 'chevron',
              Header: formatMessage({ id: 'Favorites.MonthlyFee' }),
              className: 'txt',
              minWidth: formatMessage({ id: 'Favorites.MonthlyFee' }).length > 15 ? 150:100,
              id: 'thechosenone.chosenCar.MonthlyFee',
              accessor: d => d.thechosenone.chosenCar.MonthlyPrice,
              Cell: row => <span>{row.value} kr.</span>
            },
            {
              headerClassName: 'chevron',
              Header: formatMessage({
                id: 'Favorites.ExtraordinaryLeasePaymentFirstLine'
              }),
              className: 'txt',
              id: 'thechosenone.chosenCar.Transmission',
              accessor: d => d.udbetaling,
              Cell: row => <span>{row.value} kr.</span>
            }
          ]}
          showPagination={false}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          resizable={false}
          pageSize={this.props.cars.length}
        />
      </div>
    );
  }
}

CarListFavoritesComponent.displayName = 'CarListFavoritesComponent';

// Uncomment properties you need
// CarListFavoritesComponent.defaultProps = {};

CarListFavoritesComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(withRouter(CarListFavoritesComponent));
