/*global flashEventFunction */
'use-strict'

import React from 'react';
import FilterListItem from './FilterListItemComponent.js';

import CloseModalButton from '../controls/CloseModalButtonComponent.js'
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';


class FilterGearAndFuel extends React.Component {

  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="filterside-component">
        <a href="#" onClick={this.closeWindow.bind(this)}>
          <CloseModalButton />
        </a>
        <header>{formatMessage ({ id: 'FilterGearAndFuel.Header' })}</header>
        <div className="subheader"> {formatMessage ({ id: 'FilterGearAndFuel.Fueltype' })}</div>
        {this.renderFuelItems()}
        <div className="subheader"> {formatMessage ({ id: 'FilterGearAndFuel.Geartype' })}</div>
        {this.renderTransmissionItems()}
      </div>
    );
  }

  closeWindow() {
    this.props.toggleFilterWindow('none');
    if (window.nordania.trackingInitialized) {
      // Tracking
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: Close Gear and Fuel','','','');
    }
  }



  renderTransmissionItems() {
    var self = this;
    var data = this.props.data;
    var displayName = '';
    var filterListItems = [];
    _.forIn(data.facetStore.TransmissionFilter, function (filterEntry, filterEntryName) {
      var checkmark = _.includes(data.state.filters.TransmissionFilter, filterEntryName);

      displayName = filterEntryName == 'Aut.' ? 'Automatic' : displayName;
      displayName = filterEntryName == 'Man' ? 'Manual' : displayName;

      filterListItems.push(<FilterListItem
        filterValue={filterEntryName}
        checkmark={checkmark}

        displayName={displayName}
        count={filterEntry.count}
        key={filterEntry.id}
        filterName='gear'
        categoryName='TransmissionFilter'
        filterChanged={self.filterChanged.bind(self)}
        />);


    });
    return filterListItems;

  }
  filterChanged(change) {
    this.props.filterChanged(change);
    if (window.nordania.trackingInitialized) {
      // Tracking
      let filterChangeText = change.filter + ' - ' + change.value;
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: ' + filterChangeText,'','','');
    }
    this.forceUpdate();
  }

  renderFuelItems() {
    var data = this.props.data;
    var self = this;
    var filterListItems = [];
    var displayName = '';

    const {formatMessage} = this.props.intl;


    _.forIn(data.facetStore.Fuel, function (filterEntry, filterEntryName) {
      var checkmark = _.includes(data.state.filters.Fuel, filterEntryName);
      displayName = formatMessage ({ id: ('ContentSpecs.Fuel.'+ filterEntryName) });

      filterListItems.push(<FilterListItem
        displayName={displayName}
        checkmark={checkmark}
        filterValue={filterEntryName}
        count={filterEntry.count}
        key={filterEntry.id}
        categoryName='Fuel'
        filterChanged={self.filterChanged.bind(self)}
        filterName='fuel'
        />);


    });
    return filterListItems;

  }
}

FilterGearAndFuel.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterGearAndFuel);
