/*global flashEventFunction */
//import 'core-js/fn/object/assign';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/Main.js';
import Content from './components/ContentComponent.js';
import Showroom from './components/ShowroomComponent.js';
import Favorites from './components/favorites/FavoritesComponent.js';
import Category from './components/category/CategoryComponent.js';
import SearchResults from './components/SearchResultsComponent.js';
import OrderReceived from './components/car-details/OrderReceivedComponent.js';
import CarOverview from './components/car-details/CarOverviewComponent.js';
import HelpUniverse from './components/help/HelpUniverseComponent.js';
// TODO only used when down for maintenance
// import DownForMaintenance from './components/DownForMaintenanceComponent';

import { Router, Route, browserHistory } from 'react-router'
import da from 'react-intl/locale-data/da.js';
import en from 'react-intl/locale-data/en.js';
import { IntlProvider, addLocaleData } from 'react-intl';
import localeData from './i18n/data.json';

addLocaleData([...en, ...da]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
let language = (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

if(language !== 'en-UK' && language !== 'da-DK' && language !== 'en' && language !== 'da'){
   language = 'en-UK';
}

// Listen to browser history for navigation tracking
browserHistory.listen(location => {
  // Get full path of anvigation
  let loc = location.pathname + location.search;
  if (loc !== '' && window.nordania?.trackingInitialized && window.nordania.lastTrackedLocation !== loc) {
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('PageNavigation: ' + loc,'','','');
      window.nordania.lastTrackedLocation = loc;
  }
})

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/-/)[0];
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

function render() {
  const routes = (
    <IntlProvider locale={language} messages={messages}>
      <Router history={browserHistory}>
        <Route component={App}>
          <Route component={Content}>
            <Route path="/" component={Showroom} />
            <Route path="/filter" component={Showroom} />
            <Route path="/favorites" component={Favorites} favorites='true' />
            <Route path="/history" component={Favorites} history='true' />
            <Route path="/done" component={OrderReceived} />
            <Route path="/category/:cat" component={Category} />
            <Route path="/search" component={SearchResults} />
            <Route path="details" component={CarOverview} />
            <Route path="details/:pageName" component={CarOverview} />
          </Route>
          <Route path="/help" component={HelpUniverse} />
        </Route>
        {/* This below is only used for down for maintenance - comment the routes above and uncomment below to put site into maintenance*/}
        {/* <Route path="/" component={DownForMaintenance}>
          </Route> */}
      </Router>
    </IntlProvider>);

  //ReactDOM.render(routes, document.getElementById('app'))
  ReactDOM.render(routes, document.getElementById('root'))
}

// Render the main component into the dom
// if (!window.intl) {
//   require.ensure([
//     'intl',
//     'intl/locale-data/jsonp/en.js',
//     'intl/locale-data/jsonp/da.js'
//   ], (require) => {
//     require('intl');
//     require('intl/locale-data/jsonp/en.js');
//     require('intl/locale-data/jsonp/da.js');
//     render();
//   })
// } else {
//   render();
// }
render();