// l129 changed 
//export const NewLeasingValues;

import axios from 'axios';

export function GetChosenCarData(baseUrl, carId, nyekm, nyeperiode){
  var url = baseUrl + '/api/blue/v1/GetChosenCar?carId=' + carId + '&nyekm=' + nyekm + '&nyeperiode=' + nyeperiode;
//  var self = this;

  var config = {
    url: url,
    method: 'get',
    headers: {
    }
  };

  return axios.request(config)
}

export function calculateNewMonthlyPrice(eqplist,
  baseurl,
  RegType,
  ForudsatVarditab,
  nyeperiode,
  nyekm,
  FraRegafgift,
  Pv,
  Fv,
  Oblextraeq,
  RvSats,
  originalTaxation,
  Miljofee,
  WheelPris,
  Service,
  assistance,
  winterTires,
  ekstra,
  WheelWinter,
  CarFee,
  insurance,
  Funding,
  noOfFuelCards,
  selectedColor,
  carid, 
  powersuboption
) {
  

  var url = baseurl + '/api/blue/v1/GetNewLeasingValues?regType=' +   RegType +  
  '&nyPeriode=' +  nyeperiode +
  '&nyekm=' +  nyekm +  
  '&blueService=' +  Service +
  '&assistance=' + assistance +
  '&winterTires=' + winterTires +
  '&ekstra=' + ekstra +
  '&wheelWinter=' +  WheelWinter +  
  '&insurance=' + insurance +  
  '&noOfFuelCards=' + noOfFuelCards +
  '&colorId=' +  selectedColor +
  '&carid=' +  carid + 
  '&eqplist=' + eqplist + 
  '&powersuboption=' + powersuboption;

  var config = {
  url: url,
  method: 'get',
  headers: {
  }
  };

  return axios.request(config)
}