// l129 changed 
/*global flashEventFunction */
'use strict';

import React from 'react';
import Filter from './filter/FilterComponent.js';
import FilterResults from './filter/FilterResultsComponent.js';
import SearchResults from './SearchResultsComponent.js';
import Showroom from './ShowroomComponent.js';
import Category from './category/CategoryComponent.js';
import CarOverview from './car-details/CarOverviewComponent.js';
import OrderReceived from './car-details/OrderReceivedComponent.js';
import Favorites from './favorites/FavoritesComponent.js';
import { injectIntl, intlShape  } from 'react-intl';
import FilterTypeItem from './filter/FilterQuickFilterItemComponent.js';

import '../styles/Content.scss';
import '../styles/QuickFilter.scss';

class ContentComponent extends React.Component {

  constructor(props, {router}) {
    super(props);
    this.router = router;
    this.state = {
      lastcalled: '/',
      filterSettings: {
        facets: {
          'Brand': 'Brand',
          'Model': 'Model',
          'TransmissionFilter': { 'name': 'Transmission', 'mapping': { 'Aut.': 'Automatic', 'Man': 'Manual' } },
          'Fuel': { 'name': 'Fuel type', 'mapping': { 'X': 'Hybrid', 'B': 'Benzin', 'D': 'Diesel' } },
          'EnergyCategory': 'Energy category',
          'CdpBodyType': 'Body type',
          'Kategori1': 'Kategori1',
          'Kategori2': 'Kategori2',
          'Kategori3': 'Kategori3',
          'Kategori4': 'Kategori4'
        },
        facetStore: {},
        items: [],
        state: {
          filters: {}
        },
        currentResults: []
      },
      filterState: {
        fitlerShowing: 0,
        filtersToShow: '',
        filterShown: false,
        settings: {},
        filterSliderStep: 500,
        mappings: {}
      }
    };
  }

  //MKA:
  //This is a crappy way of doing it. We should use some kind of redux/Flux state store
  //But no time for that...

  parseBack(filterSettings) {
    this.setState({ filterSettings: filterSettings });
  }
  parseStateBack(state) {
    this.state.filterState = state;
  }

  handleReset() {
    this.router.replace('/');
    this.refs.fitlerref.setupFilter();
    if (window.nordania.trackingInitialized) {
      // Tracking - Closing of filters
      flashEventFunction('Filter: Reset filters','','','');
    }
  }

  QuickFilter1(){
    this.refs.fitlerref.runQuickFilter1();
  }

  QuickFilter2(){
    this.refs.fitlerref.runQuickFilter2();
  }

  QuickFilter3(){
    this.refs.fitlerref.runQuickFilter3();
  }

  QuickFilter4(){
    try {
      this.refs.fitlerref.runQuickFilter4();
    } catch (err) {
      console.log(err);
    }
    
  }


  showFilterResults() {
    const { formatMessage } = this.props.intl;

    const childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, {
        doSomething: this.doSomething,
        lastcalled: this.state.lastcalled,
        state: this.state,
        filter: this.refs.fitlerref
      })
    );
    const anyFilter = this.state.filterSettings.enableFilterResults ? true : false;

    try {
      if (anyFilter && this.props.children.type !== CarOverview && this.props.children.type !== SearchResults &&
        this.props.children.type !== OrderReceived && this.props.children.type !== Favorites) {
       return <div className="wrapper">
                   <div className='quickFilter-component'>
             <div className='qf-btn-content'>
             <section>
               <FilterTypeItem click={() => { this.QuickFilter1(); this.refs.fitlerref.toggleMobileFilter('filter-mobile-active', true); }}
               filterText = {formatMessage({ id: 'FilterBase.QuickFilter1.Title' })}  text={formatMessage({ id: 'FilterBase.QuickFilter1' })}
               shortText={formatMessage({ id: 'FilterBase.QuickFilter1.Short' })}   shortTitleText={formatMessage({id: 'FilterBase.QuickFilter1.Title.Short'})}
                 isActiveFilter={this.refs.fitlerref.state.IsActiveQuickFilter1} />
 
               <FilterTypeItem click={() => { this.QuickFilter2(); this.refs.fitlerref.toggleMobileFilter('filter-mobile-active', true); }}
               filterText = {formatMessage({ id: 'FilterBase.QuickFilter2.Title' })}  text={formatMessage({ id: 'FilterBase.QuickFilter2' })}
               shortText={formatMessage({ id: 'FilterBase.QuickFilter2.Short' })}   shortTitleText={formatMessage({id: 'FilterBase.QuickFilter2.Title.Short'})}
                 isActiveFilter={this.refs.fitlerref.state.IsActiveQuickFilter2} />
 
               <FilterTypeItem click={() => { this.QuickFilter3(); this.refs.fitlerref.toggleMobileFilter('filter-mobile-active', true); }}
               filterText = {formatMessage({ id: 'FilterBase.QuickFilter3.Title' })} text={formatMessage({ id: 'FilterBase.QuickFilter3' })}
               shortText={formatMessage({ id: 'FilterBase.QuickFilter3.Short' })}   shortTitleText={formatMessage({id: 'FilterBase.QuickFilter3.Title.Short'})}
                 isActiveFilter={this.refs.fitlerref.state.IsActiveQuickFilter3} />
 
               <FilterTypeItem click={() => { this.QuickFilter4(); this.refs.fitlerref.toggleMobileFilter('filter-mobile-active', true); }}
               filterText = {formatMessage({ id: 'FilterBase.QuickFilter4.Title' })} text={formatMessage({ id: 'FilterBase.QuickFilter4' })}
               shortText={formatMessage({ id: 'FilterBase.QuickFilter4.Short' })}   shortTitleText={formatMessage({id: 'FilterBase.QuickFilter4.Title.Short'})}
               isActiveFilter={this.refs.fitlerref.state.IsActiveQuickFilter4} />
               </section>
             </div>
             </div>
         <FilterResults resetFilters={this.handleReset.bind(this)} cars={this.state.filterSettings.currentResults} />
       </div>;
     } else {
       return (<div className="wrapper">
         {childrenWithProps}
       </div>);
     }
    } catch (err) {
      return (<div className="wrapper">
         {childrenWithProps}
       </div>);
    }
  }
  doSomething(value) {
    this.state.lastcalled = value;
  }

  render() {    
    return (      
      <div className="content-component">
      {this.props.children.type == Showroom || this.props.children.type == Category ?
          <Filter ref="fitlerref"
            settingsSavedInContentComponent={this.state.filterSettings}
            stateSavedInContentComponent={this.state.filterState}
            parseStateBack={this.parseStateBack.bind(this)}
            parseBack={this.parseBack.bind(this)}
            parentLocationObj={this.props.location}
            doSomething={this.doSomething.bind(this)}
            handleReset ={this.handleReset.bind(this)}
            /> : ''
        }
        {this.showFilterResults()}
      </div>

    );
  }

}

ContentComponent.contextTypes = {
  baseurl: React.PropTypes.string,
  intl: intlShape.isRequired,
  router: React.PropTypes.object.isRequired
};
export default injectIntl(ContentComponent);
