'use strict';

import React from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import NavLink from '../NavLinkComponent.js';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import '../../styles/ContentColours.scss';

class ContentColoursComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      previousColor: '',
      colors: [],
      selectedColor: 0,
      errors :{
        noColorSelected: false
      }
    }
  }

  getBack(mapping) {
    let translated = 'pink';
    switch (mapping) {
      case 'Beige':
        translated = '#F5F5DC';
        break;
      case 'Blå Lys':
        translated = '#ADD8E6';
        break;
      case 'Blå Mørk':
        translated = '#000080';
        break;
      case 'Brun Lys':
        translated = '#A7764B';
        break;
      case 'Brun Mørk':
        translated = '#5C4033';
        break;
      case 'Grøn Lys':
        translated = '#32CD3C';
        break;
      case 'Grøn Mørk':
        translated = '#2F4F2F';
        break;
      case 'Grå Lys':
        translated = '#AFAFAF';
        break;
      case 'Grå Mørk':
        translated = '#808080';
        break;
      case 'Gul':
        translated = '#FFFF00';
        break;
      case 'Guld':
        translated = '#D4AF37';
        break;
      case 'Hvid':
        translated = '#FFFFFF';
        break;
      case 'Lilla':
        translated = '#800080';
        break;
      case 'Orange':
        translated = '#FFA500';
        break;
      case 'Rød':
        translated = '#FF0000';
        break;
      case 'Sort':
        translated = '#000000';
        break;
      case 'Sølv':
        translated = '#C0C0C0';
        break;
      case 'Blå':
        translated = '#000080';
        break;
      case 'Brun':
        translated = '#5C4033';
        break;
      case 'Grøn':
        translated = '#2F4F2F';
        break;
      case 'Grå':
        translated = '#808080';
        break;
    }
    if (this.props.selectedColor !== 0 && this.props.selectedColor !== null) {
      var element = document.getElementById(this.props.selectedColor);
      if (element != null) {
        element.className = 'color active';
      }
    }
    return {
      background: translated
    }

  }

  setColor(id) {
    if (id == this.state.selectedColor) {
      this.props.setSelectedColor(0);
      document.getElementById(this.state.selectedColor).className = 'color'

      this.state.selectedColor = 0;




    } else {
      //Hvis der ikke var sat en farve før
      if (this.state.selectedColor == 0) {
        document.getElementById(id).className = 'color active'

      } else {
        //Hvis der var sat en farve før
        document.getElementById(this.state.selectedColor).className = 'color'

      }
      document.getElementById(id).className = 'color active'

      this.state.selectedColor = id;
      this.props.setSelectedColor(id);


    }
  }
  componentWillMount() {


    const self = this;

    this.props.varnish?.forEach(function (element) {

      self.state.colors.push({
        id: element.Id,
        simple: element.VarnishColour,
        name: element.VarnishName,
        price: element.VarnishPrice,
        type: element.VarnishType
      })
    }, this);
    this.state.selectedColor = this.props.selectedColor;

  }
  renderColors() {
    return _.map(this.state.colors, (color, index) => <div id={color.id} key={index} onClick={this.setColor.bind(this, color.id)}
      className="color" style={this.getBack(color.simple)}><label>{color.name} ({color.type})</label></div>)
  }

  isColorSelected(id) {
    if (this.state.selectedColor == id) {
      return <div className="selected"></div>;
    } else {
      return '';
    }
  }

  colorClickedMobile(id) {
    var element = document.getElementById(this.state.selectedColor);
    if (element != null) {
      element.className = 'color';
    }
    this.state.selectedColor = id;
    document.getElementById(this.state.selectedColor).className = 'color active'
    this.props.setSelectedColor(id);

  }

  renderColorsList() {
    return _.map(this.state.colors, (color, index) =>
      <div key={index} onClick={this.colorClickedMobile.bind(this, color.id)} className="color-list-item"><div><div className="color" style={this.getBack(color.simple)}></div>
        <span>{color.name} ({color.type})</span></div>{this.isColorSelected(color.id)}<hr /></div>
    )
  }

  validate(){
    if (this.state.selectedColor === 0) {
      this.state.errors.noColorSelected = true;
    }
    else{
      this.state.errors.noColorSelected = false;
    }
  }
  render() {
    const { formatMessage } = this.props.intl;

    // var img = {
    //   backgroundImage: 'url(' + 'https://nordania.dk' + this.props.carData.Imagelarge + ')'
    // };

    if (this.props.runValidation) {
      this.validate();
    }

    return (

      <div className="contentcolours-component">
        <span className={'color-headline' + (this.state.errors.noColorSelected ? ' errorForm' : '')}><FormattedMessage id={'ColorSelection'} /></span>
        <span className="color-disclaimer"><FormattedMessage id={'ColorDisclaimer'} /></span>
        <div className="phantom"></div>
        
        <section>
          
          {
            /* TODO we may need this re-instated when we get pictures that can change color
            <div className="container">
            <div className="dummy"></div>
            <div className="car-image" style={img}></div>
          </div> */}

          <section className="color-row">
            {this.renderColors()}
          </section>

          <section className="color-list">
            {this.renderColorsList()}
          </section>
        </section>

        <section className="buttons buttons-inline small-screen-button">
         {<NavLink to="/details/equipment">
            <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
          </NavLink>}
        </section>

      </div>

    );
  }
}


ContentColoursComponent.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(ContentColoursComponent);
