// l129 changed 
import * as BlueLocalStorage from './localStorageHelper.js';

export function togglePowerSubOptionDisplay(option) {
    var ele = document.getElementsByName('pwroption-2'); 
    if (ele.length > 0) {
        var parent = ele[0].parentElement.parentElement;
        if (option > 0) {
            parent.className = parent.className.replace('checkbox-hidden') ;
        }
        else {
            parent.className = parent.className + ' checkbox-hidden';
        }
    }    
}

export function updateCachedCar(carId, car) {

    var favorites = JSON.parse(BlueLocalStorage.getItem('favorites')) || [];
    var index = favorites.indexOf(carId);

    var favoritesObjects = JSON.parse(BlueLocalStorage.getItem('favoritesObj2')) || [];
    var favObject ={};

    favoritesObjects.forEach(function(item){
        if(item.carid===carId){
            favObject=item;
        }
    })

    var objectIndex = favoritesObjects.indexOf(favObject);

    if (index === -1) {
        if(objectIndex>-1){
            favoritesObjects.splice(objectIndex, 1);
        }
    } else {
        if(objectIndex === -1){
            if(car){
            favoritesObjects.push(car);
            }
        }
        else{
            favoritesObjects.splice(objectIndex, 1);
            if(car){
                favoritesObjects.push(car);
            }
        }
    }
    BlueLocalStorage.setItem('favoritesObj2', JSON.stringify(favoritesObjects));
}

export function getCarFromCache(carId){
    var favorites = JSON.parse(BlueLocalStorage.getItem('favorites')) || [];
    var index = favorites.indexOf(carId);
    var favObject ={};
    if(index>-1){
        var favoritesObjects = JSON.parse(BlueLocalStorage.getItem('favoritesObj2')) || [];

        favoritesObjects.forEach(function(item){
            if(item.carid===carId){
                favObject= item;
            }
        })
    }

    return favObject;
}

export function setHistory(carId){
    var history = JSON.parse(BlueLocalStorage.getItem('history')) || [];
    var index = history.indexOf(carId);
    if(index>-1){
        history.splice(index, 1);
        history.push(carId);
    }
    else{
        history.push(carId);
    }
    if(history.length>50){
        history.shift();
    }
    BlueLocalStorage.setItem('history', JSON.stringify(history));

}