import React from 'react';
import { injectIntl } from 'react-intl';
import QandA from './QandAComponent.js';
import {FormattedHTMLMessage} from 'react-intl';
import '../../styles/QandAList.scss';
import image_DA from '../../images/identification_by_app.png';
import image_EN from '../../images/identification_by_app_EN.png';
class QandAList extends React.Component{
    render() {
        const {formatMessage} = this.props.intl;
        var image = this.props.intl.locale.startsWith('da') ? image_DA : image_EN;
        
        return(
            <div className="qandalist-content">
                <h1>{formatMessage({id: 'QandA.Headline'})}</h1>
                <p><FormattedHTMLMessage id='QandA.SubHeadline' /></p>
                <QandA id='question1' question='QandA.Question1' answer='QandA.Answer1' />
                <QandA id='question2' question='QandA.Question2' answer='QandA.Answer2' />
                <QandA id='question3' question='QandA.Question3' answer='QandA.Answer3' />
                <QandA id='question4' question='QandA.Question4' answer='QandA.Answer4' />
                <QandA id='question5' question='QandA.Question5' answer='QandA.Answer5' imageAnswer={image} imageAnswerClass='identification-help'/>
            </div>
        );
    }
}
export default injectIntl(QandAList);