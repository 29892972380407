import React from 'react';
import { injectIntl } from 'react-intl';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';

class NotificationFeeChanges extends React.Component {
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className='notification-box'>
        <div className='notification-window'>
          <a
            href='#'
            className='notification-close'
            onClick={() => this.props.onClose()}
          >
            <CloseModalButton />
          </a>
          <div className='notification-description'>
            {formatMessage({ id: 'Showroom.Notification' })}
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(NotificationFeeChanges);
