'use strict';

import React from 'react';
import NavChevron from '../controls/NavChevronComponent.js';

import CarCard from '../car-card/CarCardComponent.js';
import Lazyload from 'react-lazyload';
import {injectIntl, intlShape } from 'react-intl';
import { sort, filterOptions } from './../../helpers/sortHelper.js';
import '../../styles/SearchResults.scss';

class FilterResultsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data:[],
      orderBy:'Taxation',
      direction:' '
    }
  }
  change(event){
    this.setState({orderBy: event.target.value, direction:' '});
  }
  changeDirection(){
    this.setState({direction: this.state.direction=='asc'?'desc':'asc'});
  }


  render() {
    let data = this.props.cars;
    const {formatMessage} = this.props.intl;
    sort(data, this.state.direction, this.state.orderBy, (result)=>{
      data=result;
    })


    return (
      <div className="showroom-component"> {/* For left margin when showing filtered results. Search results do not have filter menu on the left */}
      <div className="searchresults-component">
        <div>
          <header className="header-area">
            <div className="reset-filter" onClick={this.props.resetFilters}>
                <NavChevron /><span>{formatMessage ({ id: 'ResetFilters' })}</span>
            </div>
            <div>
              <h1>{data.length} {formatMessage ({ id: 'FilterResults.Header' })}</h1>
            </div>
            <div className="phantom"></div>
          </header>
          <div style={{display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'}}>
            <h2 style={{position: 'relative'}}>
                <select  onChange={this.change.bind(this)} value={this.state.orderBy}>
                {filterOptions(formatMessage).map((option,i)=>
                <option value={option.id} key={i}>{option.title}</option> )
                }
              </select>
              <div className={'sortBtn '+this.state.direction} onClick={this.changeDirection.bind(this)}></div>
              </h2>
          </div>
          <section className="grid-view">
            {data.map((car, i) =>
              <CarCard car={car} key={i} />
              
            )}
          </section>
        </div>

      </div>
      </div>
    );
  }
}
FilterResultsComponent.contextTypes = {
  baseurl: React.PropTypes.string
};

FilterResultsComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterResultsComponent);
