'use strict';

import React from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { throttle, defer } from 'lodash'
import { injectIntl } from 'react-intl';
import '../../styles/controls/SearchField.scss';
import '../../styles/Favorites.scss';

class SearchFieldComponent extends React.Component {

  constructor(props, {router}) {
    super();

    this.router = router;

    this.state = {
      visible: false
    }

    this.triggerSearch = throttle(this.triggerSearch, 600);
  }

  toggleVisible() {
    this.setState({ visible: !this.state.visible });

    // Allow the DOM to be rendered before focusing the element.
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (!isIE) {
      defer(() => ReactDOM.findDOMNode(this.refs.searchInput).focus());
    }
  }

  triggerSearch(query) {
    if (query.length >= 3) {
      this.router.replace('/search?q=' + query);
    }
  }

  clearInput() {
    ReactDOM.findDOMNode(this.refs.searchInput).value = '';
    this.toggleVisible();
    this.router.replace('/');

  }

  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className={cn({ 'search-field': true, 'search-field--active': this.state.visible })}>
        <input ref="searchInput" placeholder={formatMessage({ id: 'SearchHelp' })} className="search-field__input" onInput={e => this.triggerSearch(e.target.value)} />
        <button type="button" className="search-field__button" onClick={() => this.toggleVisible()}></button>
        <button type="button" className="search-field__close" onClick={e => this.clearInput(e)}>
        </button>
      </div>
    );
  }
}


SearchFieldComponent.contextTypes = {
  router: React.PropTypes.object.isRequired
};

export default injectIntl(SearchFieldComponent);
