/*global flashEventFunction */
'use strict';

import React from 'react';
import FilterListItem from './FilterListItemComponent.js';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import '../../styles/FilterLevel01.scss';

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

class FilterBody extends React.Component {

  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="filterside-component">
        {/*<a href="#" className="reset" onClick="">Reset</a>*/}
        <header>{formatMessage ({ id: 'FilterBody.Header' })}</header>
        <a href="#" onClick={this.closeWindow.bind(this)}>
          <CloseModalButton />
        </a>
        {this.renderFilterListItems()}
      </div>
    );
  }


  closeWindow() {
    this.props.toggleFilterWindow('none');
    if (window.nordania.trackingInitialized) {
        // Tracking - Closing of filters
        flashEventFunction('Filter: Close','','','');
    }
    
  }

  filterChanged(change) {
    this.props.filterChanged(change);
    if (window.nordania.trackingInitialized) {
        // Tracking
        let filterChangeText = change.filter + ' - ' + change.value;
        // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
        flashEventFunction('Filter: ' + filterChangeText,'','','');
    }
    this.forceUpdate();
  }
  renderFilterListItems() {
    var data = this.props.data;
    var self = this;

    var filterListItems = [];
    _.forIn(data.facetStore.CdpBodyType, function (filterEntry, filterEntryName) {
      var lower = filterEntryName.toLowerCase();
      if (lower.includes('coup')) {
        lower = 'coupe';
      }
      var checkmark = _.includes(data.state.filters.CdpBodyType, filterEntryName);

      var generatedUrl = '../images/filter_cars/icn_car_' + lower + '.svg';
      filterListItems.push(<FilterListItem
        checkmark={checkmark}
        filterChanged={self.filterChanged.bind(self)}
        categoryName='CdpBodyType'

        icon={generatedUrl}
        filterValue={filterEntryName}
        count={filterEntry.count}
        key={filterEntry.id}
        filterName='bodyType'
        />);


    });
    return filterListItems;
  }
}

FilterBody.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterBody);
