'use strict';

import React from 'react';
import '../../styles/FilterListItem.scss';

class FilterListItemComponent extends React.Component {

  render() {
    let icon;
    let icnCar;
    if (this.props.icon) {
      icnCar = {
        backgroundImage: 'url(' + this.props.icon + ')'
      };
      icon = <div className="icn" style={icnCar}></div>
    } else {
      icon = null
    }

    var classname = this.GetClassName();
    var title = this.props.displayName === undefined ? this.props.filterValue : this.props.displayName;
    return (
      <div id={'li_' + title} className={classname} onClick={() => { this.onFilterItemClick(this); } } >
        <li>
          <div className="container">
            {icon}
            <span className="label">{title}</span>
          </div>
          <div className="container">
            <span className="number">{this.props.count}</span>
            {this.props.checkmark &&
              <span id={this.props.text} className='check'></span>

            }
          </div>
        </li>
      </div>
    );

  }

  GetClassName() {
    if (this.props.checkmark) {
      return 'filterlistitem-component-selected';
    } else {
      return 'filterlistitem-component';
    }

  }
  onFilterItemClick(e) {
    if(this.props.showModelPanel){
      this.props.showModelPanel(true);
    }
    this.props.filterChanged({ filter: e.props.categoryName, value: e.props.filterValue, adding: !this.props.checkmark });

  }
  openModelPanel(brand) {
    this.props.openModelPanel(brand);
  }
}

export default FilterListItemComponent;
