export function sort(data, type, key, callback) {
    data.sort((a, b) => {
        let left
        let right
        if (type === 'asc') {
            left = b[key]
            right = a[key]
        } else {
            right = b[key]
            left = a[key]
        }
        if (left < right) return -1;
        if (left > right) return 1;
        return 0;
    })
    callback(data)
}

export function filterOptions(formatMessage){return [{
    title: formatMessage ({ id: 'ContentSpecs.Taxation' }),
    id:'Taxation'
  },{
    title: formatMessage ({ id: 'ContentSpecs.MonthlyFee' }),
    id:'MonthlyPrice'
  },{
    title: formatMessage ({ id: 'ContentSpecs.FuelUsage' }),
    id:'Consumption'
  },{
    title: formatMessage ({ id: 'ContentSpecs.Co2' }),
    id:'Emissions'
  },{
    title: formatMessage ({ id: 'FilterBase.BodyType' }),
    id:'CdpBodyType'
  },{
    title: formatMessage ({ id: 'ContentSpecs.Gear' }),
    id:'Transmission'
  },{
    title: formatMessage ({ id: 'ContentSpecs.HorsePower' }),
    id:'Hk'
  }]}