/*global flashEventFunction */
'use strict';

import React from 'react';
import FilterListItem from './FilterListItemComponent.js';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';

import {injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import '../../styles/FilterLevel06.scss';

class FilterModel extends React.Component {

  render() {
    const {formatMessage} = this.props.intl;
// this.props.showModelPanel(true);
    return (
      <div className="filterside-component">
        <a href="#" onClick={this.closeWindow.bind(this)}>
          <CloseModalButton />
        </a>

        <header>{formatMessage ({ id: 'FilterModel.Header' })}</header>
        {this.renderFilterListItems()}
      </div>
    );
  }

  closeWindow() {
    // this.props.toggleFilterWindow('none');
    this.props.showModelPanel(false);
  }


  renderFilterListItems() {
    const models = this.props.models;
    const data = this.props.data;
    const self = this;
    const filterListItems = [];
    const brandsToShowModelsFor = data.state.filters.Brand.sort();

    _.forEach(brandsToShowModelsFor, function (brand) {
      filterListItems.push(<div key={brand} className="subheader"> {brand}</div>);
      _.forIn(data.facetStore.Model, function (filterEntry, filterEntryName) {

        if (models[brand].indexOf(filterEntryName) !== -1) {

          const checkmark = _.includes(data.state.filters.Model, filterEntryName);
          filterListItems.push(<FilterListItem
            checkmark={checkmark}
            filterChanged={self.filterChanged.bind(self)}
            filterValue={filterEntryName}
            count={filterEntry.count}
            key={filterEntry.id}
            categoryName='Model'
            filterName='model'
            />);
        }
      });
    });

    return filterListItems;

  }
  filterChanged(change) {
    this.props.filterChanged(change);
    //Don't need to forceupdate here - it's beeing called by props.fitlerChanged
    if (window.nordania.trackingInitialized) {
      // Tracking
      let text = change.filter + ' - ' + change.value;
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: ' + text,'','','');
    }
  }

  openModelPanel(brand) {
    this.props.openModelPanel(brand);
  }

  toggleFilterWindow() {
    this.props.toggleFilterWindow(6);
  }
}

FilterModel.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterModel);
