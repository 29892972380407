'use strict';
// l129 changed 
import React from 'react';
import {withRouter} from 'react-router';
import FavoriteButton from '../controls/FavoriteButtonComponent.js';
import PageTransition from '../PageTransition.js';
import * as BlueLocalStorage from './../../helpers/localStorageHelper.js';
import {FormattedMessage} from 'react-intl';
import '../../styles/CarCardHeader.scss';

class CarCardHeaderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick() {
    const transition = () => {
      try {
        BlueLocalStorage.setItem('currentCar', this.props.car);
      } catch (error) {
        // Do nothing
      }
      this.props.router.push({
        pathname: 'details',
        query: { carid: this.props.car.CarlistId}
      });
    };
    const isFirefox = (typeof InstallTrigger !== 'undefined');
    if (isFirefox) {
      // HACK: there seems to be someone setting state in render!
      // this causes our state transition to be stopped (somehow).
      // We are putting in a fake delay. YUK
      // transition()
      setTimeout(transition, 200);
    } else {
      this.setState({isTransitionInProgress: true});
      setTimeout(transition, 700);
    }
  }

  getQuickQuote() {
    const transition = () => {
      try {
        BlueLocalStorage.setItem('currentCar', this.props.car);
      } catch (error) {
        // Do nothing
      }
      this.props.router.push({
        pathname: 'details/quote',
        query: { carid: this.props.car.CarlistId}
      });
    };
    const isFirefox = (typeof InstallTrigger !== 'undefined');
    if (isFirefox) {
      // HACK: there seems to be someone setting state in render!
      // this causes our state transition to be stopped (somehow).
      // We are putting in a fake delay. YUK
      // transition()
      setTimeout(transition, 200);
    } else {
      this.setState({isTransitionInProgress: true});
      setTimeout(transition, 700);
    }
  }

  render() {
    let banner = this.props.car.Promotxt !== '' ? <div className="promo-text"><h2>{this.props.car.Promotxt}</h2></div> : '';
    var divStyle = {
      backgroundImage: 'url(' + 'https://nordania.dk'+this.props.car.Imagemedium + ')'
    };

    return (
      <div className="wrapper">
        {this.state.isTransitionInProgress && (
          <PageTransition />
        )}
        <a style={{cursor: 'pointer'}} onClick={this.onClick.bind(this)}>
        {/*<Link to="/details" query={{ carid: this.props.car.CarlistId}}>*/}
          <header className="carcardheader-component">
            <h2>{this.props.car.Brand + ' ' + this.props.car.Model}</h2>
            <p>{this.props.car.Description1}</p>
            <div style={divStyle}></div>
            {banner}
          </header>
        {/*  </Link>*/}
        </a>
        <div className='configureCar'>
        <button className='carConfigurationButton' onClick={this.onClick.bind(this)}>
           <FormattedMessage id={ 'CarCard.BuildYourCar' } />
        </button>
        <button className='getofferbutton' onClick={this.getQuickQuote.bind(this)}>
          <FormattedMessage id={ 'CarCard.GetAnOffer' } />      
        </button>
        </div>
        <FavoriteButton carId={this.props.car.CarlistId} />
      </div>
    );
  }
}

export default withRouter(CarCardHeaderComponent);