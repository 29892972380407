'use strict';

import React from 'react';
import '../../styles/controls/SecondaryButton.scss';

class SecondaryButtonComponent extends React.Component {

  onSaveForLaterButtonClicked() {

    var element = document.getElementById(this.props.id);

    if (element.innerText != 'Cancel') {
      element.innerText = 'Cancel';
      try {
        this.props.hideProceedBtn();
      } catch (error) {
        // Do nothing
      }
    } else {
      element.innerText = 'Save for later';
      try {
        this.props.showProceedBtn();
      } catch (error) {
        // Do nothing
      }
    }
  }


  render() {
    return (
      <div id={this.props.id}
        className="secondarybutton-component"
        onClick={this.onSaveForLaterButtonClicked.bind(this)}>
        {this.props.text}
      </div>
    );
  }
}

export default SecondaryButtonComponent;
