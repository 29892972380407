'use strict';

import React from 'react';
import FilterBase from './FilterBaseComponent.js';
import FilterBody from './FilterBody.js';
import FilterGearAndFuel from './FilterGearAndFuel.js';
import FilterLevel03 from './FilterTaxationAndPriceComponent.js';
import FilterEnvironmental from './FilterEnvironmental.js';

import FilterBrand from './FilterBrand.js';
import FilterModel from './FilterModel.js';
import axios from 'axios';
import _ from 'underscore';
import '../../styles/Filter.scss';

class FilterComponent extends React.Component {

  constructor(props, {router}) {
    super(props);
    this.state = { modelPanel: '' };
    this.settings = {};
    this.router = router;
    this.state.filterShowing = 'none';
  }

  calculateMaxValues() {
    var maxMonthly = Math.max.apply(Math, this.settings.items.map(function (o) { return o.MonthlyPrice; }));
    maxMonthly = Math.ceil(maxMonthly / this.state.filterSliderStep) * this.state.filterSliderStep;
    var minMonthly = Math.min.apply(Math, this.settings.items.map(function (o) { return o.MonthlyPrice; }));
    minMonthly = Math.floor(minMonthly / this.state.filterSliderStep) * this.state.filterSliderStep;
    var maxTax = Math.max.apply(Math, this.settings.items.map(function (o) { return o.Taxation; }));
    maxTax = Math.ceil(maxTax / this.state.filterSliderStep) * this.state.filterSliderStep;
    var minTax = Math.min.apply(Math, this.settings.items.map(function (o) { return o.Taxation; }));
    minTax = Math.floor(minTax / this.state.filterSliderStep) * this.state.filterSliderStep;
    this.state.maxRangeHp = Math.max.apply(Math, this.settings.items.map(function (o) { return o.Hk; }));
    this.state.startRangeHp = 0;
    this.state.endRangeHp = this.state.maxRangeHp;

    this.state.minRangeHp = 0;
    this.state.minRangeMonthly = minMonthly;
    this.state.startRangeMonthly = minMonthly;
    this.state.maxRangeMonthly = maxMonthly;
    this.state.endRangeMonthly = maxMonthly;
    this.state.minRangeTaxation = minTax;
    this.state.startRangeTaxation = minTax;
    this.state.maxRangeTaxation = maxTax;
    this.state.endRangeTaxation = maxTax;
  }
  componentWillMount() {
    this.state = this.props.stateSavedInContentComponent;
    this.state.mobileMenuActive = 'filter-component';
    this.settings = this.props.settingsSavedInContentComponent;
  }

  componentDidMount() {
    if (!this.state.filterShown) {

      const self = this;
      if (self.settings.items.length == 0) {
        //Get all cars
        axios(this.context.baseurl + '/api/blue/v1/GetAll')
          .then(function (response) {
            self.setState({
              cars: response.data
            });
            self.settings.items = response.data;
            self.setupFilter(Object.keys(self.props.parentLocationObj.query).length !== 0);
          })
          .catch(function () {
          }
          );
        //Get car brand <-> model mappings
        axios(this.context.baseurl + '/api/blue/v1/GetCarMappings')
          .then(function (response) {
            self.setState({
              mappings: response.data
            });
          })
          .catch(function () {
          }
          );
      }
    }
  }

  componentWillUnmount() {
    this.props.parseStateBack(this.state, this.settings);
    this.props.parseBack(this.settings);
    this.props.doSomething(this.props.parentLocationObj.pathname +
      this.props.parentLocationObj.search);
  }

  /*
  * REGION WITH FILTER CALCULATIONS. BEWARE, HERE BE DRAGONS!
  * Should be moved out to a filter service, but no hours left for it.
  */
  setupFilter(initialSetup) {
    this.calculateMaxValues();
    const self = this;
    const settings = self.settings;
    settings.enableFilterResults = false;
    _.each(settings.facets, function (facettitle, facet) {
      settings.facetStore[facet] = {};
    });
    _.each(this.settings.items, function (item) {
      _.each(settings.facets, function (facettitle, facet) {

        if (item[facet] !== undefined) {
          settings.facetStore[facet][item[facet]] = settings.facetStore[facet][item[facet]] || { count: 0, id: _.uniqueId('facet_') };
        }

      });
    });
    // sort it:
    _.each(settings.facetStore, function (facet, facettitle) {
      let sorted = _.keys(settings.facetStore[facettitle]).sort();
      if (settings.facetSortOption && settings.facetSortOption[facettitle]) {
        sorted = _.union(settings.facetSortOption[facettitle], sorted);
      }
      const sortedstore = {};
      _.each(sorted, function (el) {
        sortedstore[el] = settings.facetStore[facettitle][el];
      });
      settings.facetStore[facettitle] = sortedstore;
    });

    if (initialSetup === true) {
      _.forEach(this.props.parentLocationObj.query, function(item, key) { 
        let validFilterNames = ['CdpBodyType', 'Fuel', 'TransmissionFilter', 'startRangeTaxation', 'endRangeTaxation', 'startRangeMonthly', 
                                'endRangeMonthly', 'EnergyCategory', 'Brand', 'Model']
        if (_.has(self.state, key)) { // What is checked here? When I played, I was not able to reach inside this if statement
          self.state[key] = item;
        } else if (validFilterNames.indexOf(key) > -1) { // check if filter name is in valid array
          settings.state.filters[key] = item.split(','); // include link filter to filters
        } 
      });
      this.filter(true);
    } else {
      settings.state.filters = {};
      this.filter(false);
    }

    self.state.QuickFilter1 = 'QuickFilter1';
    self.state.QuickFilter2 = 'QuickFilter2';
    self.state.QuickFilter3 = 'QuickFilter3';
    self.state.QuickFilter4 = 'QuickFilter4';
    self.state.IsActiveQuickFilter1 = false;
    self.state.IsActiveQuickFilter2 = false;
    self.state.IsActiveQuickFilter3 = false;
    self.state.IsActiveQuickFilter4 = false;
  }

  resetFacetCount() {

    const { settings } = this;
    _.each(settings.facetStore, function (items, facetname) {
      _.each(items, function (value, itemname) {
        settings.facetStore[facetname][itemname].count = 0;
      });
    });
  }

  filter(forceShowResults) {
    const self = this;
    const { settings } = self;

    let queryString = '';
    if (self.state.startRangeMonthly != self.state.minRangeMonthly) {
      queryString += `&startRangeMonthly=${self.state.startRangeMonthly}`;
    }
    if (self.state.endRangeMonthly != self.state.maxRangeMonthly) {
      queryString += `&endRangeMonthly=${self.state.endRangeMonthly}`;
    }
    if (self.state.startRangeTaxation != self.state.minRangeTaxation) {
      queryString += `&startRangeTaxation=${self.state.startRangeTaxation}`;
    }
    if (self.state.endRangeTaxation != self.state.maxRangeTaxation) {
      queryString += `&endRangeTaxation=${self.state.endRangeTaxation}`;
    }
    if (self.state.startRangeHp != self.state.minRangeHp) {
      queryString += `&startRangeHp=${self.state.startRangeHp}`;
    }
    if (self.state.endRangeHp != self.state.maxRangeHp) {
      queryString += `&endRangeHp=${self.state.endRangeHp}`;
    }
    if (Object.keys(settings.state.filters).length !== 0) {
      queryString += '&' + Object.keys(settings.state.filters).map(key => key + '=' + settings.state.filters[key]).join('&');
    }
    if (queryString) {
      this.router.replace('/filter?' + queryString.substr(1));
    } else {
      this.router.replace('/');
    }

    // first apply the filters to the items
    settings.currentResults = this.getCurrentResultsItems();
    settings.currentResults = this.filterOnMonthlyPrice(settings.currentResults);
    settings.currentResults = this.filterOnTaxation(settings.currentResults);
    settings.currentResults = this.filteronHp(settings.currentResults);
    if (forceShowResults === false) {
      settings.enableFilterResults = false;
    } else {
      settings.enableFilterResults = true;
    }

    // Update the count for each facet and item:
    // intialize the count to be zero
    this.resetFacetCount();

    // then reduce the items to get the current count for each facet
    _.each(settings.facets, function (facettitle, facet) {

      _.each(self.getResultsetFromAllOtherFacets(facet), function (item) {
        if (item[facet] !== undefined) {

          settings.facetStore[facet][item[facet]].count += 1;
        }
      });
    });
    this.props.parseBack(settings);

  }

  getCurrentResultsItems() {
    const self = this;
    const { settings } = self;
    return _.select(settings.items, function (item) {

      var filtersApply = true;
      _.each(settings.state.filters, function (filter, facet) {

        if (filter.length && _.indexOf(filter, item[facet]) == -1) {
          filtersApply = false;
        }
      });
      return filtersApply;
    });
  }

  filterOnMonthlyPrice(currentResults) {
    const self = this;

    return _.select(currentResults, function (item) {
      if (item.MonthlyPrice <= self.state.endRangeMonthly && item.MonthlyPrice >= self.state.startRangeMonthly) {
        return true;
      } return false;
    });
  }
  filterOnTaxation(currentResults) {
    const self = this;
    return _.select(currentResults, function (item) {
      if (item.Taxation <= self.state.endRangeTaxation && item.Taxation >= self.state.startRangeTaxation) {
        return true;
      } return false;
    });
  }
  filteronHp(currentResults) {
    const self = this;

    return _.select(currentResults, function (item) {
      if (item.Hk <= self.state.endRangeHp && item.Hk >= self.state.startRangeHp) {
        return true;
      } return false;
    });
  }
  getResultsetFromAllOtherFacets(facet) {
    const { settings } = this;
    const changedFilters = _.clone(settings.state.filters);
    delete changedFilters[facet];
    //If we are trying to count up the numbers to show in Brand pane, model selections should not count
    if (facet === 'Brand') {
      delete changedFilters['Model'];
    }
    let items = this.filterOnMonthlyPrice(settings.items);
    items = this.filterOnTaxation(items);

    const changedResults = _.select(items, function (item) {
      let changedFiltersApply = true;
      _.each(changedFilters, function (filter, facet) {

        if (filter.length && _.indexOf(filter, item[facet]) == -1) {
          changedFiltersApply = false;
        }

      });

      return changedFiltersApply;
    });
    return changedResults;

  }
  changedTaxation(values) {

    this.state.startRangeTaxation = values[0];
    this.state.endRangeTaxation = values[1];
    this.filter();
  }
  changedHp(values) {

    this.state.startRangeHp = values[0];
    this.state.endRangeHp = values[1];
    this.filter();
  }
  changedMonthly(values) {
    this.setState({ startRangeMonthly: values[0], endRangeMonthly: values[1] });
    this.filter();
  }

  toggleFilter(key, value) {
    var { settings } = this;
    settings.state.filters[key] = settings.state.filters[key] || [];
    if (_.indexOf(settings.state.filters[key], value) === -1) {
      settings.state.filters[key].push(value);
    } else {
      settings.state.filters[key] = _.without(settings.state.filters[key], value);
      if (settings.state.filters[key].length === 0) {
        delete settings.state.filters[key];
      }
    }
    this.filter();
  }


  resetFilters(){
    this.state.filtersToShow = '';
    this.state.filterShowing = 0;
    this.state.mobileMenuActive = 'filter-component';
    this.props.handleReset();

  }
  addFilter(key, value) {
    var { settings } = this;
    settings.state.filters[key] = settings.state.filters[key] || [];
    settings.state.filters[key].push(value);
    this.filter();

  }
  removeFilter(key, value) {
    var { settings } = this;
    settings.state.filters[key] = settings.state.filters[key] || [];
    settings.state.filters[key] = _.without(settings.state.filters[key], value);
    if (settings.state.filters[key].length === 0) {
      delete settings.state.filters[key];
    }
    this.filter();

  }

  /*
   * END OF FILTER CALCULATIONS
   */

  filterChanged(change) {
    const self = this;
    //If we are removing a brand from the filter
    if (change.filter == 'Brand' && !change.adding) {
      _.forEach(self.state.mappings[change.value], function (modelToRemove) {
        if (self.settings.state.filters.Model !== undefined) {
          const index = self.settings.state.filters.Model.indexOf(modelToRemove);
          if (index !== -1) {
            self.settings.state.filters.Model.splice(index, 1);
            if (self.settings.state.filters.Model.length == 0) {
              delete self.settings.state.filters.Model;
            }
          }
        }
      })

    }

    self.toggleFilter(change.filter, change.value);

  }

  quickFilterToggleLevel(level){
    this.setQuickFilters(level);
  }

  runQuickFilter1(){
    this.setQuickFilters(this.state.QuickFilter1);
  }

  runQuickFilter2(){
    this.setQuickFilters(this.state.QuickFilter2);
  }

  runQuickFilter3(){
    this.setQuickFilters(this.state.QuickFilter3);
  }

  runQuickFilter4(){
    this.setQuickFilters(this.state.QuickFilter4);
  }

  setQuickFilters(level){
    if(level === this.state.QuickFilter1){
      !this.state.IsActiveQuickFilter1
      //? (this.resetFilters(), this.filterChanged({filter: 'CdpBodyType', value: 'Suv'}) , this.state.IsActiveQuickFilter1 = !this.state.IsActiveQuickFilter1)
      ? (this.resetFilters(), this.filterChanged({filter: 'Fuel', value: 'E'}) , this.state.IsActiveQuickFilter1 = !this.state.IsActiveQuickFilter1)
      : this.resetFilters();
    }

    if(level === this.state.QuickFilter2){
      !this.state.IsActiveQuickFilter2
      ? (this.resetFilters(), this.filterChanged({filter: 'CdpBodyType', value: 'Stationcar'}) , this.state.IsActiveQuickFilter2 = !this.state.IsActiveQuickFilter2)
      : this.resetFilters();
    }

    if(level === this.state.QuickFilter3){
      !this.state.IsActiveQuickFilter3
      ? (this.resetFilters(), this.filterChanged({filter: 'TransmissionFilter', value: 'Aut.' }), this.state.IsActiveQuickFilter3 = !this.state.IsActiveQuickFilter3)
      : this.resetFilters();
    }

    if(level === this.state.QuickFilter4){
      !this.state.IsActiveQuickFilter4
      ? (this.resetFilters(), this.filterChanged({filter: 'Fuel', value: 'X' }), this.state.IsActiveQuickFilter4 = !this.state.IsActiveQuickFilter4)
      : this.resetFilters();
    }

  }

  toggleMobileFilter(classToShow, show) {
    if (!show) {
      this.setState({ mobileMenuActive: 'filter-component' });
    } else {
      this.setState({ mobileMenuActive: 'filter-component ' + classToShow });
    }

  }

  render() {
    return (
      <div className={this.state.mobileMenuActive}>
        <FilterBase ref="child" toggleMobileFilter={this.toggleMobileFilter.bind(this)} toggleFilterWindow={this.toggleFilterWindow.bind(this)} quickFilterToggleLevel ={this.quickFilterToggleLevel.bind(this)} showExpandedFilter={this.state.filtersToShow !== ''} />
        {this.state.filtersToShow}
        {this.renderModelPanel()}
      </div>
    );
  }


  toggleFilterWindow(level) {

    if (level === this.state.filterShowing) {
      
      this.state.filtersToShow = '';
      this.state.filterShowing = 0;
      this.state.mobileMenuActive = 'filter-component';
      this.context.uiStore.showHeaderIOSBug(true);

    } else {
      switch (level) {
        case 1:
          this.context.uiStore.showHeaderIOSBug(false);

          this.state.filtersToShow = <div className="filter-container"> <FilterBody
            key="FilterBody"
            data={this.settings}
            filterChanged={this.filterChanged.bind(this)}
            toggleFilterWindow={this.toggleFilterWindow.bind(this)}
          /></div>
            ;
          break;
        case 2:
          this.context.uiStore.showHeaderIOSBug(false);

          this.state.filtersToShow = <div className="filter-container"> <FilterGearAndFuel
            key="FilterGearAndFuel"
            data={this.settings}
            filterChanged={this.filterChanged.bind(this)}
            toggleFilterWindow={this.toggleFilterWindow.bind(this)}
          /></div>
            ;
          break;
        case 3:
          this.context.uiStore.showHeaderIOSBug(false);

          this.state.filtersToShow = <div className="filter-container"> <FilterLevel03
            key="FilterLevel03"
            changedTaxation={this.changedTaxation.bind(this)}
            changedMonthly={this.changedMonthly.bind(this)}
            startValues={this.state}
            toggleFilterWindow={this.toggleFilterWindow.bind(this)}
          /></div>
            ;
          break;
        case 4:
          this.context.uiStore.showHeaderIOSBug(false);

          this.state.filtersToShow = <div className="filter-container"> <FilterEnvironmental
            key="FilterEnvironmental"
            data={this.settings}
            filterChanged={this.filterChanged.bind(this)}
            toggleFilterWindow={this.toggleFilterWindow.bind(this)}
          /></div>
            ;
          break;
        case 5:
          this.context.uiStore.showHeaderIOSBug(false);

          this.state.showModelPanel = true;
          this.state.filtersToShow = <div className="filter-container"> <FilterBrand
            key="FilterBrand"
            data={this.settings}
            filterChanged={this.filterChanged.bind(this)}
            toggleFilterWindow={this.toggleFilterWindow.bind(this)}
            showModelPanel={this.showModelPanel.bind(this)}
          /></div>
            ;
          break;
        case 'none':
          this.state.filtersToShow = '';
          this.state.mobileMenuActive = 'filter-component';

          var ref = this.refs.child.getWrappedInstance();
          this.context.uiStore.showHeaderIOSBug(true);

          ref.setHideOnMobile();
          break;
      }
      this.state.filterShowing = level;
    }
    this.forceUpdate();

  }

  renderModelPanel() {
    if (this.settings.state.filters.hasOwnProperty('Brand') && this.state.filterShowing == 5 && this.state.showModelPanel) {
      return <FilterModel
        key="FilterModel"
        data={this.settings}
        models={this.state.mappings}
        filterChanged={this.filterChanged.bind(this)}
        toggleFilterWindow={this.toggleFilterWindow.bind(this)}
        showModelPanel={this.showModelPanel.bind(this)}
      />;
    } else {
      return '';
    }
  }
  showModelPanel(status) {
    this.setState({ showModelPanel: status });
  }
}

FilterComponent.contextTypes = {
  baseurl: React.PropTypes.string,
  uiStore: React.PropTypes.object,
  router: React.PropTypes.object.isRequired
};

export default FilterComponent;
