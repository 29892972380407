'use strict';

import React from 'react';
import CarCardHeader from './CarCardHeaderComponent.js';
import { injectIntl, intlShape, FormattedNumber } from 'react-intl';
import '../../styles/CarCardFavorites.scss';

class CarCardFavoritesComponent extends React.Component {
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="carcardfavorites-component">
        <CarCardHeader car={this.props.car.chosenCar} />
        <section>
          <ul>
            <li>
              {formatMessage({ id: 'Favorites.Tax' })}
              <span><FormattedNumber value={this.props.car.Taxation} /> {formatMessage({ id: 'Favorites.Taxation.unit' })}</span>
            </li>
            <li>
              {formatMessage({ id: 'Favorites.Fuelusage' })}
              <span>{this.props.car.Consumption} {formatMessage({ id: 'Favorites.Fuelusage.unit' })}</span> {/*Skal rettes*/}
            </li>
            <li>
              {formatMessage({ id: 'Favorites.CO2' })}

              <span>{this.props.car.Emissions} {formatMessage({ id: 'Favorites.CO2.unit' })}</span> {/*Skal rettes*/}
            </li>
            <li>
              {formatMessage({ id: 'Favorites.Body' })}
              <span>{this.props.car.CdpBodyType}</span> {/*Skal rettes*/}
            </li>
            <li>
              {formatMessage({ id: 'Favorites.Gear' })}
              <span>{this.props.car.Transmission}</span> {/*Skal rettes*/}
            </li>
            <li>
              {formatMessage({ id: 'Favorites.Effect' })}
              <span>{this.props.car.Hk} {formatMessage({ id: 'Favorites.Effect.unit' })}</span> {/*Skal rettes*/}
            </li>
            <li>
              {formatMessage({ id: 'Favorites.MonthlyFee' })}
              <span><FormattedNumber value={this.props.car.MonthlyPrice} /> {formatMessage({ id: 'Favorites.Taxation.unit' })}</span>
            </li>
            <li>
              {formatMessage({ id: 'Favorites.ExtraordinaryLeasePaymentFirstLine' })}<br />
              {formatMessage ({ id: 'Favorites.ExtraordinaryLeasePaymentSecondLine' })}
              <span><FormattedNumber value={this.props.car.udbetaling} /> {formatMessage({ id: 'Favorites.Taxation.unit' })}</span>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

CarCardFavoritesComponent.displayName = 'CarCardFavoritesComponent';

// Uncomment properties you need
// CarCardFavoritesComponent.defaultProps = {};

CarCardFavoritesComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(CarCardFavoritesComponent);
