import React from 'react';
import { injectIntl } from 'react-intl';
import {FormattedHTMLMessage} from 'react-intl';
import '../../styles/QandA.scss';

class QandA extends React.Component{
    constructor(props) {
        super(props);
    
        this.state = {
          showAnswer: false
        }
      }

    componentDidMount() {
        var element = document.getElementById(this.props.id);
        
        var self = this;
        element.onclick = function (event) {
            event.stopPropagation();
    
            if (element.childElementCount === 2) {
                var name = element.children[1].className;
                if (name == 'chevron-up') {
                    element.children[1].className = 'chevron-down';
            
                    self.setState({
                    showAnswer: false
                    });
                } else {
                    element.children[1].className = 'chevron-up';
            
                    self.setState({
                        showAnswer: true
                    });
                }
            }
        };
    }

    renderAnswer() {
        if (this.state.showAnswer) {
            if(this.props.imageAnswer !== undefined){
                return <section className="q-and-a-answer">
                <img className={this.props.imageAnswerClass} src={this.props.imageAnswer} />
                </section>
            }
            else {
                return <section className="q-and-a-answer">
                <p><FormattedHTMLMessage id={this.props.answer} /></p>
                </section>
            }
        }
    }
    render() {
        const {formatMessage} = this.props.intl;
        
        return(
            <div>
                <section className="q-and-a-dropdown">
                    <div id={this.props.id}>
                        <h3>{formatMessage({ id: this.props.question })}</h3>
                        <div className='chevron-down' ></div>
                    </div>
                    <hr />
                </section>

                {this.renderAnswer()}
            </div>
        );
    }
}
export default injectIntl(QandA);