/*global flashEventFunction */
'use strict';

import React from 'react';
import FilterListItem from './FilterListItemComponent.js';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import '../../styles/FilterLevel01.scss'

class FilterEnvironmental extends React.Component {

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className="filterside-component">
        <a href="#" onClick={this.closeWindow.bind(this)}>
          <CloseModalButton />
        </a>
        <header>{formatMessage({ id: 'FilterEnvironmental.Header' })}</header>

        {this.renderItems()}
      </div>
    );
  }

  closeWindow() {
    this.props.toggleFilterWindow('none');
    if (window.nordania.trackingInitialized) {
      // Tracking
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: Close Environmental','','','');
    }
  }
  filterChanged(change) {
    this.props.filterChanged(change);
    if (window.nordania.trackingInitialized) {
      // Tracking
      let text = change.filter + ' - ' + change.value;
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: ' + text,'','','');
    }
    this.forceUpdate();

  }
  renderItems() {
    const data = this.props.data;
    const filterListItems = [];
    const list = ['A+++', 'A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];
    list.forEach(function (element) {
      if (data.facetStore.EnergyCategory.hasOwnProperty(element)) {
        filterListItems.push(this.renderFilterListItem(data.facetStore.EnergyCategory[element], element));
      }

    }, this);
    return filterListItems;
  }
  renderFilterListItem(filterEntry, filterEntryName) {
    const data = this.props.data;
    const self = this;

    const checkmark = _.includes(data.state.filters.EnergyCategory, filterEntryName); 
    const generatedUrl = '../images/filter_cars/cat_' + filterEntryName.replace(/\+/g, 'plus') + '.svg';
    return (<FilterListItem
      checkmark={checkmark}
      filterChanged={self.filterChanged.bind(self)}
      icon={generatedUrl}
      filterValue={filterEntryName}
      count={filterEntry.count}
      key={filterEntry.id}
      categoryName='EnergyCategory'
      filterName='env'
    />);

  }
}

FilterEnvironmental.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(FilterEnvironmental);
