'use strict';

import React from 'react';
import NavChevron from './controls/NavChevronComponent.js';
import PrimaryButton from './controls/PrimaryButtonComponent.js';
import { Link } from 'react-router';

import axios from 'axios';
import CarCard from './car-card/CarCardComponent.js';
import Lazyload from 'react-lazyload';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import * as BlueLocalStorage from './../helpers/localStorageHelper.js';
import '../styles/SearchResults.scss';
import loading from '../images/ring-alt.gif';

class SearchResultsComponent extends React.Component {

  constructor(props) {
    super(props);
    const query = props.location.query.q;
    this.executeSearch(query, false);
  }

  componentWillMount() {
  }
  componentWillReceiveProps(nextProps) {
    const query = nextProps.location.query.q;
    if (query.length >= 3) {
      this.executeSearch(query);
    }
  }


  executeSearch(query, updateState = true) {
    const encodedQuery = encodeURIComponent(query);
    const aftalenr = BlueLocalStorage.getItem('aftalenr') || 0;

    const url = window.nordania.url + '/api/blue/v1/Search?q=' + encodedQuery + '&aftalenr=' + aftalenr;
    // const url = 'http://nordania-enhancement-test.azurewebsites.net' + '/api/appenhancement/v1/Search?q=' + encodedQuery + '&aftalenr=' + aftalenr;
    this.props.doSomething('search?q=' + encodedQuery);

    var config = {
      url: url,
      method: 'get',
      headers: {
      }
    };

    axios.request(config).then(({ data }, err) => {
      if ({ data }) {
        this.setState({ data });
      } else if (err) {
        alert(err);
      }
    }).catch(function (error) {
      alert(error);
    });

    const state = {
      data: null
    };

    if (updateState) {
      this.setState(state);
    } else {
      this.state = state
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { data } = this.state;    

    let status;

    if (data === null) {
      status = 'LOADING';
    } else if (data.length == 0) {
      status = 'NO_RESULTS';
    }
    else {
      status = 'RESULTS';
    }

    return (
      <div className="searchresults-component">

        {(status === 'LOADING') && (
          <div className="loading"><img src={loading} alt="loading" /></div>
        )}

        {(status === 'NO_RESULTS') && (
          <div className="no-results">
            <header className="header-area">
              <div>
                <Link to="/">
                  <NavChevron />
                </Link>
              </div>
              <h1>{formatMessage({ id: 'SearchResults.NoResultsHeader' })}</h1>
              <div className="phantom"></div>
            </header>
            <p><FormattedMessage id={'SearchResults.NoResultsBody'} /></p>
            <Link to="/">
              <PrimaryButton text={formatMessage({ id: 'Back' })} />
            </Link>
          </div>
        )}

        {(status === 'RESULTS') && (
          <div>
            <header className="header-area">
              <div>
                <Link to="/">
                  <NavChevron />
                </Link>
              </div>
              <h1>{data.length} {formatMessage({ id: 'SearchResults.ResultsHeader' })}</h1>
              <div className="phantom"></div>
            </header>
            <section className="grid-view">
              {data.map(car =>
                <CarCard car={car} key={car.CarlistId} />
              )}
            </section>
          </div>
        )}
      </div>
    );
  }
}
SearchResultsComponent.contextTypes = {
  baseurl: React.PropTypes.string
};

SearchResultsComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(SearchResultsComponent);
