/*global flashEventFunction */
'use strict';

import React from 'react';
import ContentSection from './ContentSectionComponent.js';
import { injectIntl, intlShape } from 'react-intl';
import * as BlueLocalStorage from './../helpers/localStorageHelper.js';
import NordaniaBlueIntroVideo from './help/NordaniaBlueIntroVideo.js';
import FilterTypeItem from './filter/FilterQuickFilterItemComponent.js';
import '../styles/Showroom.scss';
import '../styles/QuickFilter.scss';

class ShowroomComponent extends React.Component {
  componentWillMount() {
    this.props.doSomething('/');
    let videoShown = BlueLocalStorage.getItem('videoShown') || false;
    this.state = { videoShown: videoShown};
    try {
      BlueLocalStorage.setItem('videoShown', true);
    } catch (error) {
      // Do nothing
    }    
  }  

  closeVideo() {
    this.setState({ videoShown: true });
    // setItem may fail - in private mode on safari for instance
    try {
      BlueLocalStorage.setItem('videoShown', true);
    } catch (error) {
      // Do nothing
    }
  }

  QuickFilter1() {
    this.props.filter.runQuickFilter1();
  }

  QuickFilter2() {
    this.props.filter.runQuickFilter2();
  }

  QuickFilter3() {
    this.props.filter.runQuickFilter3();
  }

  QuickFilter4() {
    this.props.filter.runQuickFilter4();
  }

  resetFilters() {
    this.props.filter.resetFilters();
  }

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className="showroom-component">
        {!this.state.videoShown && (
          <NordaniaBlueIntroVideo closeVideo={() => this.closeVideo()} />
        )}
        <div className="wrapper-showroom">
          <header>
            <div className="showroom__title">
              <div className="header-container">
                <h2>
                  {Object.keys(this.props.state.filterSettings.items).length}
                  {formatMessage({ id: 'Showroom.TotalCars' })}
                </h2>
              </div>
              <p>{formatMessage({ id: 'Showroom.OthersHaveSearchFor' })}</p>
            </div>
            <div className='quickFilter-component'>
              <div className='qf-btn-content'>
                <section>
                  <FilterTypeItem
                    click={() => {
                      this.QuickFilter1();
                      this.props.filter.toggleMobileFilter(
                        'filter-mobile-active',
                        true
                      );
                    }}
                    filterText={formatMessage({
                      id: 'FilterBase.QuickFilter1.Title'
                    })}
                    level={'QuickFilter1'}
                    text={formatMessage({ id: 'FilterBase.QuickFilter1' })}
                    shortText={formatMessage({
                      id: 'FilterBase.QuickFilter1.Short'
                    })}
                    shortTitleText={formatMessage({
                      id: 'FilterBase.QuickFilter1.Title.Short'
                    })}
                  />

                  <FilterTypeItem
                    click={() => {
                      this.QuickFilter2();
                      this.props.filter.toggleMobileFilter(
                        'filter-mobile-active',
                        true
                      );
                    }}
                    filterText={formatMessage({
                      id: 'FilterBase.QuickFilter2.Title'
                    })}
                    level={'QuickFilter2'}
                    text={formatMessage({ id: 'FilterBase.QuickFilter2' })}
                    shortText={formatMessage({
                      id: 'FilterBase.QuickFilter2.Short'
                    })}
                    shortTitleText={formatMessage({
                      id: 'FilterBase.QuickFilter2.Title.Short'
                    })}
                  />

                  <FilterTypeItem
                    click={() => {
                      this.QuickFilter3();
                      this.props.filter.toggleMobileFilter(
                        'filter-mobile-active',
                        true
                      );
                    }}
                    filterText={formatMessage({
                      id: 'FilterBase.QuickFilter3.Title'
                    })}
                    level={'QuickFilter3'}
                    text={formatMessage({ id: 'FilterBase.QuickFilter3' })}
                    shortText={formatMessage({
                      id: 'FilterBase.QuickFilter3.Short'
                    })}
                    shortTitleText={formatMessage({
                      id: 'FilterBase.QuickFilter3.Title.Short'
                    })}
                  />

                  <FilterTypeItem
                    click={() => {
                      this.QuickFilter4();
                      this.props.filter.toggleMobileFilter(
                        'filter-mobile-active',
                        true
                      );
                    }}
                    filterText={formatMessage({
                      id: 'FilterBase.QuickFilter4.Title'
                    })}
                    level={'QuickFilter4'}
                    text={formatMessage({ id: 'FilterBase.QuickFilter4' })}
                    shortText={formatMessage({
                      id: 'FilterBase.QuickFilter4.Short'
                    })}
                    shortTitleText={formatMessage({
                      id: 'FilterBase.QuickFilter4.Title.Short'
                    })}
                  />
                </section>
              </div>
            </div>
          </header>
          

          <ContentSection
            title={formatMessage({ id: 'Category.Kategori1' })}
            type="Kategori1"
            limit="3"
          />
          <ContentSection
            title={formatMessage({ id: 'Category.Kategori2' })}
            type="Kategori2"
            limit="3"
          />
          <ContentSection
            title={formatMessage({ id: 'Category.Kategori3' })}
            type="Kategori3"
            limit="3"
          />
          <ContentSection
            title={formatMessage({ id: 'Category.Kategori4' })}
            type="Kategori4"
            limit="3"
          />
          
          
               

        </div>
      </div>      
    );        
  }
}

// Uncomment properties you need
ShowroomComponent.propTypes = {
  intl: intlShape.isRequired
};

ShowroomComponent.defaultProps = {};

export default injectIntl(ShowroomComponent);
