'use strict';

import React from 'react';
import { injectIntl, intlShape  } from 'react-intl';
import NavLink from '../NavLinkComponent.js';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import '../../styles/ContentCar.scss';

class ContentCarComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { formatMessage } = this.props.intl;

    var img = {
      backgroundImage: 'url(' + 'https://nordania.dk' + this.props.carData.Imagelarge + ')'
    };
    const generatedUrl = '../images/filter_cars/cat_' + this.props.carData.EnergyCategory.replace(/\+/g, 'plus') + '.svg';

    var icnCar = {
      backgroundImage: 'url(' + generatedUrl + ')',
      backgroundRepeat: 'no-repeat'
    };

    let banner = this.props.carData.Promotxt !== '' ? <div className="promo-text">{this.props.carData.Promotxt}</div> : '';

    return (
      <div>
        <div className="contentcar-component">
          <div className="phantom"></div>
          <div className="container">
            <div className="dummy"></div>
            <div className="car-image" style={img}></div>
            {banner}
          </div>
          <div className="icn-environment" style={icnCar}></div>

          <section>
            <section className="stats">
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.KilometerLiter' })}</label>
                <span>{this.props.carData.Consumption}</span>
              </div>
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.Co2' })} pr.kilometer</label>
                <span>{this.props.carData.Emissions} g</span>
              </div>
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.EnviromentalCategory' })}</label>
                <span>{this.props.carData.EnergyCategory}</span>
              </div>
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.Body' })}</label>
                <span>{this.props.carData.CdpBodyType}</span>
              </div>
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.HorsePower' })}</label>
                <span>{this.props.carData.Hk}</span>
              </div>
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.Gear' })}</label>
                <span>{this.props.carData.Transmission}</span>
              </div>
              <div>
                <label>{formatMessage ({ id: 'ContentSpecs.Fuel' })}</label>
                <span>{formatMessage ({ id: ('ContentSpecs.Fuel.'+ this.props.carData.Fuel) })}</span>
              </div>
            </section>
            <section className="extras">
              <header>{formatMessage ({ id: 'ContentSpecs.Extras.Included' })}</header>
              <div>
                <span>{formatMessage ({ id: 'ContentSpecs.Extras.OperationaLease' })}</span>
                <span>{formatMessage ({ id: 'ContentSpecs.Extras.StandardTires' })}</span>
                <span>{formatMessage ({ id: 'ContentSpecs.Extras.ServiceAgreement' })}</span>
              </div>
            </section>
          </section>
        </div>
        <section className="buttons buttons-inline small-screen-button">
          {<NavLink to="/details/colour">
            <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
          </NavLink>}
        </section>
      </div>
    );
  }
}

ContentCarComponent.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(ContentCarComponent);