// l129 changed 
'use strict';

import React from 'react';
import RadioButton from '../controls/RadioButtonComponent.js';
import DynamicBoundsAnnualMilage from '../DynamicBoundsAnnualMilage.js';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import SecondaryButton from '../controls/SecondaryButtonComponent.js';
import Checkbox from '../controls/CheckboxComponent.js';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import '../../styles/ContentQuote.scss';

class ContentQuoteComponent extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      formIsClean: false,
      showInfoOverlay: false,
      informationOverlayText: '',
      form: {
        name: '',
        company: '',
        phone: '',
        email: '',
        errors: {
          name: false,
          company: false,
          phone: false,
          email: false
        }
      }
    }
  }

  renderDefaultRadioButton() {
    var isDefault = (this.props.checkedReadioButtons || this.props.checkedReadioButtons.length) == 0;
    return <RadioButton
      isDefault={isDefault}
      processRadioButtons={this.props.processRadioButtons}
      id='radioButton36'
      groupName='leasingPeriod'
      labelText='36'
      checkedReadioButtons={this.props.checkedReadioButtons}
    />
  }

  renderPowerSubscriptionOptions() { 

    var pwrinputcss = 'horizontal';
    var pwrchkinput = document.getElementsByName('pwroption-1')[0];
    if (!(typeof pwrchkinput !== 'undefined' && pwrchkinput.checked)) {
      pwrinputcss += ' checkbox-hidden';
    }
    
    
    var data = [
      {
        "typeid": 1,
        "typename": "Nordania ladeabonnement", 
        "css": "horizontal"
      },
      {
        "typeid": 2,
        "typename": "Nordania ladestander", 
        "css": pwrinputcss 
      }
    ];    
    if (data && localStorage.getItem('VehicleHasPluginRecharge') == 'true') {      
      const items = _.map(data.slice(0, 1003), (equipment, index) => { 
        return <Checkbox
          key={index}
          {...equipment}
          checkBoxText={equipment.typename}
          id={equipment.typeid} 
          name={'pwroption-' + equipment.typeid}
          checkedItems={this.props.checkedItems}
          togglePowerSubscription={this.props.togglePowerSubscription}
          css={equipment.css}
          databaseId={equipment.typeid}
          equipmentType={'pwrsub'} 
        />
      });
      return items;
    }
  }

  parseBackFormState() {
    if (this.state.form.errors.name === false && this.state.form.name !== '' &&
      this.state.form.errors.company === false && this.state.form.company !== '' &&
      this.state.form.errors.phone === false && this.state.form.phone !== '' &&
      this.state.form.errors.email === false && this.state.form.email !== '') {
      this.setState({ formIsClean: true });
      this.props.checkoutFormStatus(true);
    } else {
      this.setState({ formIsClean: false });
      this.props.checkoutFormStatus(false);
    }
  }

  toggleInfoConsentOverlay(){
    let language = navigator.language || navigator.userLanguage;
    if (language.indexOf('da') > -1) {
      window.open('https://nordania.dk/erhverv/diverse/persondatapolitik', "_blank");
    } else {
      window.open('https://nordania.dk/erhverv/diverse/persondatapolitik-en', "_blank");
    }
  }

  checkForChars(chars, stringToCheck) {
    for (var i = 0; i < chars.length; i++) {
      if (stringToCheck.indexOf(chars[i]) > -1) {
       return true
      }
    }
    return false;
  }

  nameChange(e) {
    this.state.form.name = e.target.value;
    this.validateName(e.target.value);
    this.parseBackFormState();
    this.forceUpdate();
  }

  validateName(nameText){
    if (nameText === '' || this.checkForChars(':\\.[]', nameText)) {
      this.state.form.errors.name = true
    } else {
      this.state.form.errors.name = false
    }
  }

  companyChange(e) {
    this.state.form.company = e.target.value;
    this.validateCompany(e.target.value);
    this.parseBackFormState();
    this.forceUpdate();
  }

  validateCompany(companyText){
    if (companyText === '' || this.checkForChars(':\\.[]', companyText)) {
      this.state.form.errors.company = true
    } else {
      this.state.form.errors.company = false
    }
  }

  phoneChange(e) {
    this.state.form.phone = e.target.value;
    this.validatePhone(e.target.value);
    this.parseBackFormState();
    this.forceUpdate();
  }

  validatePhone(phoneText){
    if (/^\d+$/.test(phoneText)) {
      this.state.form.errors.phone = false
    } else {
      this.state.form.errors.phone = true
    }
  }

  emailChange(e) {
    this.state.form.email = e.target.value;
    this.validateEmail(e.target.value);
    this.parseBackFormState();
    this.forceUpdate();
  }

  validateEmail(emailText){
    if (/^(.+)@(.+){2,}\.(.+){2,}$/.test(emailText)) {
      this.state.form.errors.email = false
    } else {
      this.state.form.errors.email = true;
    }
  }

  validate(){
    // Check name
    this.validateName(this.state.form.name);
    // Check company
    this.validateCompany(this.state.form.company);
    // Check phone
    this.validatePhone(this.state.form.phone);
    // Check email
    this.validateEmail(this.state.form.email);
  }

  toggleChargeInfo() {
    this.context.uiStore.show('ChargeSubscriptionInfo');
  }

  render() {
    const { formatMessage } = this.props.intl;
    if (this.props.runValidation) {
      this.validate();
    }
    let pwroption = '';
    if (localStorage.getItem('VehicleHasPluginRecharge') == 'true') {
      pwroption = <div className="charge-sub-info">
        <h3><FormattedMessage id={'Leasing.Leasing.ChargeSubscription'} />
          <a className="legal" onClick={() => { this.toggleChargeInfo() }}></a>
        </h3>      
        
      </div>      
    }
    return (
      <div className="contentquote-component">
        <section>
          <section className="container-top">
            <section>
              <h3><FormattedMessage id={'Leasing.Leasing.LeasingPeriod'} /></h3>
              <RadioButton
                processRadioButtons={this.props.processRadioButtons}
                id='radioButton24'
                groupName='leasingPeriod'
                labelText='24'
                checkedReadioButtons={this.props.checkedReadioButtons}
              />
              {this.renderDefaultRadioButton()}
              <RadioButton
                processRadioButtons={this.props.processRadioButtons}
                id='radioButton48'
                groupName='leasingPeriod'
                labelText='48'
                checkedReadioButtons={this.props.checkedReadioButtons}
              />
              <RadioButton
                processRadioButtons={this.props.processRadioButtons}
                id='radioButton60'
                groupName='leasingPeriod'
                labelText='60'
                checkedReadioButtons={this.props.checkedReadioButtons}
              />
            </section>
            <section>
              <h3>
                <FormattedMessage id={'Leasing.Leasing.AnnualMilage'} />{this.props.milage}
              </h3>
              <DynamicBoundsAnnualMilage
                milage={this.props.milage}
                processMilage={this.props.processMilage}
              />
            </section>
            <section>                            
              {pwroption}
              {this.renderPowerSubscriptionOptions()}                          
            </section>
          </section>
          <section className="container-bottom">
              <div>
                <section className="input">
                  <form>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.name ? ' errorForm' : '')} onChange={this.nameChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.NamePlaceholder' })} value={this.state.form.name} />
                    </div>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.company ? ' errorForm' : '')} onChange={this.companyChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.CompanyPlaceholder' })} value={this.state.form.company} />
                    </div>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.phone ? ' errorForm' : '')} onChange={this.phoneChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.PhonePlaceholder' })} value={this.state.form.phone} />
                    </div>
                    <div className="FormInput">
                      <input className={'input__field' + (this.state.form.errors.email ? ' errorForm' : '')} onChange={this.emailChange.bind(this)}
                        placeholder={formatMessage({ id: 'ContentCheckout.EmailPlaceholder' })} value={this.state.form.email} />
                    </div>
                    <div className="info-consent">
                      <div onClick={() => { this.toggleInfoConsentOverlay() }} className="icon"></div>
                      <div className="message">{formatMessage({ id: 'ContentCheckout.Consent' })}</div>
                    </div>
                  </form>
                </section>
              </div>
          </section>
        </section>

        <section className="buttons buttons-inline">
          <div onClick={this.props.sendComplete.bind(this)}>
            <PrimaryButton text={formatMessage({ id: 'ContentCheckout.SendMobile' })} disabled={!this.state.formIsClean} />
            <SecondaryButton />
          </div>
        </section>
      </div>
    );
  }
}

ContentQuoteComponent.propTypes = {
  intl: intlShape.isRequired
};

ContentQuoteComponent.contextTypes = {
  baseurl: React.PropTypes.string,
  router: React.PropTypes.object.isRequired,
  uiStore: React.PropTypes.object,
  uiStore: React.PropTypes.object.isRequired
};

export default injectIntl(ContentQuoteComponent, { withRef: true });
