import React from 'react';
import InfoOverlay from './InfoOverlayComponent.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
//var ReactCSSTransitionGroup = require('react-addons-css-transition-group');
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
//import PropTypes from 'prop-types';

class InfoPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      shown: false,
      messageId: null,
      videoShown: false,
      message: ''
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillMount() {
    let callback = {
      setMessageId: (messageId) => this.setMessageId(messageId),
      setMessageUrl: (messageUrl) => this.setMessageUrl(messageUrl)
    }
    this.props.uiStore.listen(callback);
    this.props.uiStore.listenVideo(this.setVideoVisibility.bind(this));
  }

  componentDidMount(){
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.close();
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) this.close();
  }

  setMessageId(messageId) {
    let message = <FormattedHTMLMessage id={messageId} />
    this.setState({ message: message, shown: true });
  }

  setMessageUrl(url) {
    const styles = {
      width: '100%',
      height: '60%'
    };

    let message = <iframe src={url} scrolling="no" style={styles} />
    this.setState({ message, shown: true})
  }

  setVideoVisibility(videoShown) {
    this.setState({ videoShown });
  }

  close() {
    this.setState({ shown: false });
  }

  render() {
    return (
      <div ref={this.setWrapperRef}>
        <ReactCSSTransitionGroup transitionName="slide"
          transitionAppear
          transitionAppearTimeout={1000}
          transitionEnter
          transitionEnterTimeout={1000}
          transitionLeave
          transitionLeaveTimeout={1000}>
          {this.state.shown && this.state.message && <InfoOverlay text={this.state.message} update={this.close.bind(this)} />}
        </ReactCSSTransitionGroup>

      </div>
    );
  }
}

//InfoPanel.propTypes = {
  //children: PropTypes.element.isRequired,
//};

export default injectIntl(InfoPanel);
