'use strict';

import React from 'react';
import { Link } from 'react-router';

class NavLinkComponent extends React.Component {
  constructor(props) {

    super(props);

    this.state = {}
  }
  componentWillMount() {
    const language = (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/-/)[0];
    if (languageWithoutRegionCode == 'da') {
      this.state.language = 'da';
    } else {
      this.state.language = 'en';

    }
  }
  closePrice(){
    if(this.props.closePrice){
      this.props.closePrice()
    }
  }
  render() {
    let isActive = this.context.router.isActive(this.props.to, true);
    let label = '';
    if(this.props.label !== undefined){
      label = this.state.language == 'da' ? this.props.label.titleDa : this.props.label.titleEn;
    }
    return (
      <div onClick={this.closePrice.bind(this)} className={(isActive ? 'selected' : '') + this.props.className}>
        <Link to={this.props.to}>
          {label}
          {this.props.children}
        </Link>
      </div>
    );
  }

  static contextTypes = {
    router: React.PropTypes.object
  };
}

export default NavLinkComponent;
