'use strict';

import React from 'react';
import '../../styles/QuickFilter.scss';

class FilterQuickFilterItemComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hover: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleMouseIn() {
        this.setState({ hover: true })
    }

    handleMouseOut() {
        this.setState({ hover: false })
    }

    handleClick(){
        this.props.click(this.props.level);
    }

    getClassName(){
        if(this.props.isActiveFilter){
            return 'selectedFilter';
        }
        return '';
    }

    render(){
          return (
          <a title={this.props.text} id={this.props.level} onClick={() => this.handleClick()} onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)} className={this.getClassName()} >
            <div className='hide-on-desktop'>
                <div className='long-filter-title-text'>
                    {this.props.shortTitleText}
                </div>

                <div className='long-filter-text'>
                    {this.props.text}
                </div>

                <div className='short-filter-title-text'>
                    {this.props.shortTitleText}
                </div>

                <div className='short-filter-text'>
                    {this.props.shortText}
                </div>
            </div>

            <div>
                <div>
                    <span className='quickFilter-title'>{this.props.filterText}</span>
                </div>
                <div className='quickFilterText-Div'>
                    <span className="quickFilter-text">
                    {this.props.text}
                    </span>
                </div>
            </div>
          </a>
        );
    }
}

export default FilterQuickFilterItemComponent