'use strict';
// l129 changed - hide header with modal button... 
import React from 'react';
import CarCardHeader from './CarCardHeaderComponent.js';
import { FormattedNumber, injectIntl, intlShape } from 'react-intl';
import CarOfferForm from '../car-details/CarOfferComponent.js';
import '../../styles/CarCard.scss';

class CarCardComponent extends React.Component {

  constructor() {
    super();
    this.state = { showModal : false };
  }

  

  showModal() {
    this.setState({
      showModal : !this.state.showModal
    });
  }
  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="carcard-component">
        <CarCardHeader car={this.props.car} />
        
        <header className="carofferheader hidden">
          <button className="getofferbutton" onClick={this.showModal.bind(this)}> {formatMessage ({ id: 'CarCard.GetAnOffer' })}</button>
          {this.state.showModal && (<CarOfferForm closeModal={() => this.closeModal()} car={this.props.car} />)}
        </header>

        <section>
          <div>
            <span className="label">
              {formatMessage ({ id: 'CarCard.TaxFrom' })}
            </span>

            <span className="price-tag">
              <FormattedNumber  value={this.props.car.Taxation} /> kr.
            </span>
          </div>
          <div>
            <span className="label">
              {formatMessage ({ id: 'CarCard.MonthlyPayment' })}
            </span>
            <span className="price-tag">
              <FormattedNumber value={this.props.car.MonthlyPrice} /> kr.
            </span>
      
          </div>
        </section>
      </div>
    );
  }
}

CarCardComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(CarCardComponent);
