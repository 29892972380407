/*global flashEventFunction */

'use strict';

import React from 'react';
import * as BlueLocalStorage from './../../helpers/localStorageHelper.js';
import { injectIntl, intlShape } from 'react-intl';
import * as BlueLocalStorageCar from './../../helpers/localStorageCarHelper.js';
import '../../styles/controls/FavoriteButton.scss';


class FavoriteButtonComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavorite: false

        };
    }

    componentDidMount() {
        const favorites = JSON.parse(BlueLocalStorage.getItem('favorites')) || [];
        const notFavorite = (favorites.indexOf(this.props.carId) === -1);
        this.setState({isFavorite : !notFavorite});
    }

    render() {
        const favorites = JSON.parse(BlueLocalStorage.getItem('favorites')) || [];
        const notFavorite = (favorites.indexOf(this.props.carId) === -1);
        const isFavorite = !notFavorite;
        const { formatMessage } = this.props.intl;

        let fillColor;
        let accentColor = '#009FDA';

        if (!isFavorite) {
            fillColor = 'none';
        } else {
            fillColor = accentColor;
        }

        return (
            <div title={formatMessage({ id: 'SaveToFavorites' })} className="favoritebutton-component"  onClick={() => { this.toggleFavorite(this.props.carId, this.props.car) } }>
                <svg width="13px" height="13px" viewBox="0 0 13 13" xmlns="http://www.w3.org/svg/2000">
                    <g fill={fillColor} stroke={accentColor} strokeWidth="0.5" strokeMiterlimit="10">
                        <polygon points="6.5,1.273 4.723,4.714 0.75,5.266 3.625,7.944 2.947,11.727 6.5,9.94 10.054,11.727 9.375,7.944 12.25,5.266 8.277,4.714"></polygon>
                    </g>
                </svg>
            </div>
        );
    }

    toggleFavorite(carId, car) {
        var self = this;

        var favorites = JSON.parse(BlueLocalStorage.getItem('favorites')) || [];
        var index = favorites.indexOf(carId);
        if (index === -1) {
            favorites.push(carId);
            self.setState({
                isFavorite: true
            });
            if (window.nordania.trackingInitialized) {
                // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
                flashEventFunction('Favorites: Add to favorites: ' + carId,'','','');
            }
        } else {
            favorites.splice(index, 1);
            self.setState({
                isFavorite: false
            });
            if (window.nordania.trackingInitialized) {
                // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
                flashEventFunction('Favorites: Remove from favorites: ' + carId,'','','');
            }
        }
        BlueLocalStorage.setItem('favorites', JSON.stringify(favorites));
        BlueLocalStorageCar.updateCachedCar(carId,car);
    }
}

FavoriteButtonComponent.propTypes = {
    intl: intlShape.isRequired
  };

export default injectIntl(FavoriteButtonComponent);
