import _ from 'lodash';

export const menuItems = [
  { pageName: 'car', href: '/details', titleDa: '1. Bil', titleEn: '1. Car' },
  { pageName: 'colour', href: '/details/colour', titleDa: '2. Farver', titleEn: '2. Colours' },
  { pageName: 'equipment', href: '/details/equipment', titleDa: '3. Udstyr', titleEn: '3. Equipment' },
  { pageName: 'quote', href: '/details/quote', titleDa: '4. Få et tilbud', titleEn: '4. Quote' }
];

export const Directions = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

export const getDirection = function (from, to) {
  const indexOfFrom = _.findIndex(menuItems, x => x.pageName === from);
  const indexOfTo = _.findIndex(menuItems, x => x.pageName === to);
  return (indexOfFrom < indexOfTo) ? Directions.RIGHT : Directions.LEFT;
};

export default {
  menuItems,
  Directions,
  getDirection
};
