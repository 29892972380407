// l129 changed 
'use strict';

import React from 'react';
import _ from 'lodash'

import '../../styles/controls/Checkbox.scss';

class CheckboxComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isChecked: false,
      databaseId: -1
    }
  }

  checkChanged(id, dbId, e) {
    var sentValues = this.props;
    if (e.target.checked) {
      if (!String.prototype.includes) {
        String.prototype.includes = function () {
          'use strict';
          return String.prototype.indexOf.apply(this, arguments) !== -1;
        };
      }
      //WHAT THE FUCK!?
      else if (this.props.type == 'fuel') {
        this.props.addToFuelCardList(id);
      }

      var tmp = _.find(sentValues.checkedItems, x => x == id);
      if (!tmp) {
        try {
          this.props.checkedItems.push(id);
        } catch (error) {
          // Fail silently
        }

        

        if (this.props.addToSelectedEquipment) {
          this.props.addToSelectedEquipment(dbId);
        }

        if (this.props.id == 'accepInsurance') {
          this.props.toggleAcceptance(' ');

          this.setState({
            isChecked: true
          });
        } else if (this.props.id == 'consent') {
          this.setState({
            isChecked: true
          });
          this.props.toggleConsent(true);
        }
        // else if (this.props.id == 'rejectInsurance') {
        //   this.props.toggleAcceptance('accepInsurance');

        //   this.setState({
        //     isChecked: false
        //   });
        // }
        else {
          this.setState({
            isChecked: true
          });
        }

        if (this.props.addToEquipmentExpenses) {
          this.props.addToEquipmentExpenses(sentValues.Price, this.props.equipmentType)
        }

        /*if (this.props.enableShowLowRiskAsDefault) {
          this.props.enableShowLowRiskAsDefault();
        }*/
      }

    } else {      
      var tmp = _.find(this.props.checkedItems, x => x == id);
      if (this.props.removeFromSelectedEquipmentList) {
        this.props.removeFromSelectedEquipmentList(dbId)
      }

      if (tmp) {
        _.remove(this.props.checkedItems, x => x == tmp);
        if (tmp == 'accepInsurance') {
          this.props.toggleAcceptance('accepInsurance');
        }else if (this.props.id == 'consent') {
          this.props.toggleConsent(false);
        }

        this.setState({
          isChecked: false
        });

        if (this.props.reduceEquipmentExpenses) {
          this.props.reduceEquipmentExpenses(sentValues.Price, this.props.equipmentType);
        }
      }

      if (!String.prototype.includes) {
        String.prototype.includes = function () {
          'use strict';
          return String.prototype.indexOf.apply(this, arguments) !== -1;
        };
      }
      //WHAT THE FUCK!?
      else if (this.props.type == 'fuel') {
        this.props.removeFromFuelCarsList(id);
      }
    }

    // power subscription options... ended up pretty weird 
    if (this.props.togglePowerSubscription) {
      if (!e.target.checked) {
        if (id > 1) { 
          id = 1;          
        }
        else {
          id = 0;
        }
      } else {
        var pwropt2 = document.getElementsByName('pwroption-2')[0];        
        if (id == 1 && pwropt2.checked) {
          id = 2;  
        }
      }
      this.props.togglePowerSubscription(id);
    }

    if (this.props.disableInsuranceDefaultSelection) {
      this.props.disableInsuranceDefaultSelection();
    }

    /*if (id === 'assistance' || id === 'winterTires' || id === 'ekstra') {
      this.props.recalculate(id, e.target.checked);
    }*/
  }

  render() {
    var result = _.find(this.props.checkedItems, x => x == this.props.id);

    this.state.databaseId = this.props.databaseId;

    if (this.props.isDefault) {
      this.state.isChecked = true;
    }
    else if (result) {
      this.state.isChecked = true;
    } else {
      this.state.isChecked = false;
    }

    var classname = 'checkbox-component ' + this.props.css;

    return (
      <div className={classname}>
        <label>
          <input ref="inputfield"
            onChange={this.checkChanged.bind(this, this.props.id, this.props.databaseId)}
            type="checkbox"
            // if strange errors starts to occur, reverse the below change for the "name" attribute 
            //name="name"
            name={this.props.name}
            id={this.props.id}
            value=''
            checked={this.state.isChecked}
            />
          <span>{this.props.checkBoxText}</span>
        </label>
      </div>
    );
  }
}

export default CheckboxComponent;
