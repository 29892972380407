'use strict';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import '../../styles/controls/DoublePrimaryButton.scss';

class DoublePrimaryButtonComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isSendingMail: false,
      succesText: ''
    }
  }

  render() {

    return this.renderButtons();
  }

  renderButtons() {
    if (!this.state.isSendingMail) {
      return <div>{this.state.succesText}<div id='wrapper' className='doubleprimarybutton-component'>
        <div id='saveToPdfBtn' className='left' onClick={this.saveToPdf.bind(this)}><FormattedMessage id={ 'SaveToPdf' } /></div>
        <div id='saveToEmailBtn' className='right' onClick={this.sendMail.bind(this)}><FormattedMessage id={ 'SaveToEmail' } /></div>
      </div></div>
    } else {
      return <div id='wrapper2' className='doubleprimarybutton-component'>
        {this.state.succesText}
        <input className='input'
          id='emailAddress'
          placeholder='Email'
          />
        <div id='sendEmailOut' className='right' onClick={this.sendEmailOut.bind(this)}><FormattedMessage id={ 'SaveToEmail.Send' } /></div>
      </div>
    }
  }

  sendEmailOut() {

    var mail = document.getElementById('emailAddress').value;
    this.props.sendOrderSummary(true, mail, false);
    this.setState({isSendingMail:false, succesText:'Mail sent'});
  }



  saveToPdf() {
        this.props.sendOrderSummary(false, '', false);
  }

  sendMail() {
    this.setState({
      isSendingMail: true
    });
  }
}

export default DoublePrimaryButtonComponent;


