/*global s_sitecatalyst */
import React from 'react';
import * as BlueLocalStorage from './../helpers/localStorageHelper.js';

class TrackingComponent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
          scriptLoaded: false
        }
    }

    componentWillMount(){
        if(BlueLocalStorage.getItem('cookiesAccepted') === 'true'){
            // s_account script tag
            var script = document.createElement('script');
            if (window.nordania.environment === 'prod') {
                // PROD setting
                script.text = 'var s_account = "dbgnordaniahomepage";'
            }
            else{
                // TEST and DEV setting
                script.text = 'var s_account = "danskebprod";'
            }
            document.getElementsByTagName('body')[0].appendChild(script);

            var s_codeScript = document.createElement('script');
            s_codeScript.type = 'text/javascript';
            //s_codeScript.src = 'https://nordania.dk/_layouts/1030/s_code.js?rev=8rnKKsXg6OSFNByRrz%2FerA%3D%3D58';
            s_codeScript.src = 'https://nordania.dk/-/media/nordania-filer/s_code.js';

            //when the script loads, we're ready to go, so change state
            s_codeScript.onload = (function(){
                this.setState({scriptLoaded: true})
            }).bind(this);

            document.getElementsByTagName('body')[0].appendChild(s_codeScript);
        }
    }

    setTrackingVariables(){
        if (window.nordania.trackingInitialized !== 'true') {
            // s_sitecatalyst is defined in the s_code.js script that is remotely hosted
            s_sitecatalyst.trackDownloadLinks='true';
            s_sitecatalyst.trackExternalLinks='true';
            s_sitecatalyst.trackInlineStats='true';
            s_sitecatalyst.linkDownloadFileTypes='exe,zip,wav,mp3,mov,mpg,avi,wmv,doc,pdf,xls';
            s_sitecatalyst.linkInternalFilters='javascript:,nordania.dk';
            s_sitecatalyst.linkLeaveQueryString='true';
            s_sitecatalyst.linkTrackVars='None';
            s_sitecatalyst.linkTrackEvents='None';
            s_sitecatalyst.usePlugins='true';
            if (window.nordania.environment === 'prod') {
                // PROD setting
                s_sitecatalyst.server='Homepage Nordania';
            }
            else{
                // TEST and DEV setting
                s_sitecatalyst.server=' X Test 1 ';
            }
            s_sitecatalyst.charSet='utf-8';
            s_sitecatalyst.pageName='Blue_Frontpage/da-dk/';
            s_sitecatalyst.channel='da-dk';
            s_sitecatalyst.eVarX=s_sitecatalyst.pageName;
            s_sitecatalyst.eVarY=s_sitecatalyst.channel;

            if (window.nordania.environment === 'prod') {
                s_sitecatalyst.trackingServer='p1.danskebank.dk'; //this is for content on .dk domain only
                s_sitecatalyst.trackingServerSecure='p2.danskebank.dk'; //this is for content on .dk domain only
            }

            var s_code=s_sitecatalyst.t();if(s_code)document.write(s_code);
            window.nordania.trackingInitialized = 'true';
            window.nordania.lastTrackedLocation = '/';
        }

    }

    render(){
        if (this.state.scriptLoaded) {
            this.setTrackingVariables();
        }
        return(null);
    }
}
export default TrackingComponent