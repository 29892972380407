'use strict';

import React from 'react';
import '../../styles/FilterBase.scss';

class FilterTypeItemComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hover: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleMouseIn() {
        this.setState({ hover: true })
    }
    
    handleMouseOut() {
        this.setState({ hover: false })
    }

    handleClick(){
        this.props.click(this.props.level);
    }

    render(){
          return (<a title={this.props.text} id={this.props.level} onClick={() => this.handleClick()} onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)} >
            <div className={this.props.filterClass}></div>
            
            <div className='hide-on-desctop'>
                <div className='long-filter-text'>{this.props.text}</div>
                <div className='short-filter-text'>{this.props.shortText}</div>
            </div>
            
            <span className="right">{this.props.text}</span>
          </a>);
    }
}

export default FilterTypeItemComponent