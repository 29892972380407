'use strict';

import React from 'react';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import NavLink from '../NavLinkComponent.js';
import '../../styles/OrderReceived.scss';

class OrderReceivedComponent extends React.Component {
  render() {
        const {formatMessage} = this.props.intl;

    return (
      <div className="orderreceived-component">
        <div className="top">
          <h1><FormattedMessage id={ 'OrderReceived.Header' } /></h1>
          <p><FormattedMessage id={ 'OrderReceived.BodyFirstLine' } /> <FormattedMessage id={ 'OrderReceived.BodySecondLine' } /></p>
          <div className="approved"></div>
         <NavLink to="/">

        <PrimaryButton text={formatMessage({ id: 'OrderReceived.Ok' })} />
</NavLink>        </div>
        <p className="bottom-text"><FormattedMessage id={ 'OrderReceived.BottomText' }/>&nbsp;<a className="bottom-link" href="https://danskebank.dk/erhverv/bliv-kunde?" target="_blank"><FormattedMessage id={ 'OrderReceived.BottomLink' }/></a></p>

      </div>
    );
  }
}

// Uncomment properties you need
OrderReceivedComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(OrderReceivedComponent);

