'use strict';

import React from 'react';
import {Link} from 'react-router';

import axios from 'axios';

import CarCard from '../car-card/CarCardComponent.js';
import NavChevron from '../controls/NavChevronComponent.js';
import {FormattedMessage} from 'react-intl';
import '../../styles/Favorites.scss';


class CategoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: []
    };
  }

  componentDidMount() {
    var self = this;
    axios(this.context.baseurl + '/api/blue/v1/GetCategoryCars',
      {
        params: {
          type: this.props.params.cat
        }
      })
      .then(function (response) {
        self.setState({cars: response.data});
      })
      .catch(function () {
      });
  }

  render() {
    let content;



    content = <section className="grid-view"> {
      this.state.cars.map((car, i) => {
        return <CarCard key={i} car={car} isFavorite={true}/>
      })
    }</section>




    return (
            <div className="showroom-component">

      <div className="wrapper-showroom">
        <header className="header-area">
          <div>
            <Link to="/">
              <NavChevron />
            </Link>
          </div>
          <h1> <FormattedMessage id={ 'Category.'+this.props.params.cat } /></h1>
          <div className="phantom-show-more"></div>
        </header>
        {content}
      </div>

          </div>
    );
  }



}
CategoryComponent.contextTypes = {
  baseurl: React.PropTypes.string
};

CategoryComponent.displayName = 'CategoryComponent';


export default CategoryComponent;
