'use strict';

import React from 'react';
import Slider from 'rc-slider'
import { FormattedNumber } from 'react-intl';
import '../styles/controls/rc-slider/assets/index.scss';
//import '../rc-slider/assets/index.scss'

class DynamicBounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      min: this.props.minValue,
      max: this.props.maxValue,
      minValue: this.props.startRangeFrom,
      maxValue: this.props.startRantgeTo

    };
  }


  render() {
    return <div>
      <Slider range step={this.props.step} defaultValue={[this.state.minValue, this.state.maxValue]} min={this.state.min} max={this.state.max} allowCross={false}
        onChange={this.onSliderChange.bind(this)}
        />
      <div className="label-left"><FormattedNumber value={this.state.minValue} /> kr.
</div>
      <div className="label-right"><FormattedNumber value={this.state.maxValue} /> kr.</div>
    </div>
  }
  //TODO Maybe have onAfterChange actually post back the new values
  onSliderChange(value) {
    this.setState({ minValue: value[0] });
    this.setState({ maxValue: value[1] });
    this.props.parseback([value[0], value[1]]);
  }
}

export default DynamicBounds;
