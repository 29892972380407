'use strict';

import React from 'react';
import '../../styles/controls/NavChevron.scss';

class NavChevronComponent extends React.Component {
  render() {
    return (
      <div className="navchevron-component">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="#003755" fillRule="evenodd">
          <path d="M10.6251492,15 C10.4624862,15 10.2998231,14.9387513 10.1752842,14.8175038 L3.18585527,7.94264703 C2.93804824,7.69765213 2.93804824,7.30266036 3.18585527,7.05766547 L10.1752842,0.182808691 C10.424362,-0.0609362305 10.8259365,-0.0609362305 11.0750143,0.182808691 C11.3228213,0.427803587 11.3228213,0.822795358 11.0750143,1.06779025 L4.53417965,7.50015625 L11.0750143,13.9325222 C11.3228213,14.1775171 11.3228213,14.5725089 11.0750143,14.8175038 C10.9504754,14.9387513 10.7878123,15 10.6251492,15"/>
        </svg>
      </div>
    );
  }
}

export default NavChevronComponent;
