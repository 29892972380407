// Safari Private Browsing does not allow access to localStorage
// therefore we have this solution that fall-back to MemoryStorage
export function setItem(key,value) {
    //var MemoryStorage = require('memorystorage');
    var BlueLocalStorage;  // our alias for localStorage
    if (window.nordania.useLocalStorage === undefined) {
        TestUseLocalStorage();
    }
    if (window.nordania.useLocalStorage === 'true') {
        BlueLocalStorage = localStorage; // localStorage is fully functional!
    } else {
        //BlueLocalStorage = new MemoryStorage(); // fall back to a memory-based implementation
    }
        
    if (BlueLocalStorage.getItem('cookiesAccepted') !== 'false') {
        BlueLocalStorage.setItem(key,value);
    }
}
// Safari Private Browsing does not allow access to localStorage
// therefore we have this solution that fall-back to MemoryStorage
export function getItem(key) {
    //var MemoryStorage = require('memorystorage');
    var BlueLocalStorage;  // our alias for localStorage
    if (window.nordania.useLocalStorage === undefined) {
        TestUseLocalStorage();
    }
    if (window.nordania.useLocalStorage === 'true') {
        BlueLocalStorage = localStorage; // localStorage is fully functional!
    } else {
        //BlueLocalStorage = new MemoryStorage(); // fall back to a memory-based implementation
    }
    return BlueLocalStorage.getItem(key);
}

export function clearStorage(){
    //var MemoryStorage = require('memorystorage');
    var BlueLocalStorage;  // our alias for localStorage
    if (window.nordania.useLocalStorage === undefined) {
        TestUseLocalStorage();
    }
    if (window.nordania.useLocalStorage === 'true') {
        BlueLocalStorage = localStorage; // localStorage is fully functional!
    } else {
        //BlueLocalStorage = new MemoryStorage(); // fall back to a memory-based implementation
    }
    BlueLocalStorage.clear();
}

function TestUseLocalStorage(){
    try {
        if (window.nordania.useLocalStorage === undefined) {
            var x = 'test-localstorage-' + Date.now();
            localStorage.setItem(x, x);
            var y = localStorage.getItem(x);
            localStorage.removeItem(x);
            if (y !== x) {throw new Error();}
            window.nordania.useLocalStorage = 'true';
        }
    } catch (e) {
        window.nordania.useLocalStorage = 'false';
    }
}