/*global flashEventFunction */

import React from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import NordaniaBlueIntroVideo from '../help/NordaniaBlueIntroVideo.js';
import '../../styles/WhatIsBlue.scss';
import screenShot_iphone_DA from '../../images/iphone_screenshot.jpg';
import screenShot_iphone_EN from '../../images/iphone_screenshot_EN.jpg';
//import '../../styles/devices.min.scss';

class WhatIsBlue extends React.Component{
    componentWillMount() {
        // Set to shown be default to keep the video from popping
        this.state = { videoShown: true }
    }

    showVideo() {
        this.setState({ videoShown: false });
        if (window.nordania.trackingInitialized) {
            // Tracking - Closing of filters
            flashEventFunction('Video: See how easy it is','','','');
        }
    }

    closeVideo() {
        this.setState({ videoShown: true });
        // setItem may fail - in private mode on safari for instance
    }
    render() {
        const {formatMessage} = this.props.intl;        
        var screenShot = this.props.intl.locale.startsWith('da') ? screenShot_iphone_DA : screenShot_iphone_EN;

        return(
            <div className="what-is-blue-container">
                {!this.state.videoShown && (<NordaniaBlueIntroVideo closeVideo={() => this.closeVideo()} />)}
                <div className="what-is-blue-header">
                    <h1>{formatMessage({id:'WhatIsBlue.Headline'})}</h1>
                    <h2>{formatMessage({id:'WhatIsBlue.SubHeadline'})}</h2>
                </div>
                <div className="what-is-blue-column-container">
                    <div className="what-is-blue-left-column">
                        <h1>{formatMessage({id:'WhatIsBlue.HowItWorks.Headline'})}</h1>
                        <p><FormattedHTMLMessage id={'WhatIsBlue.HowItWorks.Text'} /></p>
                        <div className="video-button-container">
                            <a className="video-button" onClick={() => this.showVideo()}>{formatMessage({ id: 'Showroom.Video' })}</a>
                        </div>
                    </div>
                    <div className="what-is-blue-right-column">
                        <div className="marvel-device iphone8 silver">
                            <div className="notch">
                                <div className="camera"></div>
                                <div className="speaker"></div>
                            </div>
                            <div className="top-bar"></div>
                            <div className="sleep"></div>
                            <div className="bottom-bar"></div>
                            <div className="volume"></div>
                            <div className="overflow">
                                <div className="shadow shadow--tr"></div>
                                <div className="shadow shadow--tl"></div>
                                <div className="shadow shadow--br"></div>
                                <div className="shadow shadow--bl"></div>
                            </div>
                            <div className="inner-shadow"></div>
                            <div className="screen">
                                <img className="screenshot" src={screenShot} />
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        );
    }
}
export default injectIntl(WhatIsBlue);