/*global flashEventFunction */
'use strict';

import React from 'react';
import DynamicBounds from '../DynamicBounds.js';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';
import { injectIntl, intlShape } from 'react-intl';
import '../../styles/FilterLevel01.scss';

class FilterLevel03Component extends React.Component {

  parsebackTaxation(values) {
    this.props.changedTaxation(values);
    // Get full path of anvigation
    let taxRange = values[0] + ' - ' + values[1];
    if (window.nordania.trackingInitialized && window.nordania.lastTrackedTaxRange !== taxRange) {
        // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
        flashEventFunction('Filter: TaxRange: ' + taxRange,'','','');
        window.nordania.lastTrackedTaxRange = taxRange;
    }
  }
  parsebackMonthly(values) {
    this.props.changedMonthly(values);
    // Get full path of anvigation
    let monthlyRange = values[0] + ' - ' + values[1];
    if (window.nordania.trackingInitialized && window.nordania.lastTrackedMonthlyRange !== monthlyRange) {
        // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
        flashEventFunction('Filter: MonthlyRange: ' + monthlyRange,'','','');
        window.nordania.lastTrackedMonthlyRange = monthlyRange;
    }
  }
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="filterside-component">
        <header>{formatMessage ({ id: 'FilterTaxationAndPrice.Header' })}</header>

        <a href="#" onClick={this.closeWindow.bind(this)}>
          <CloseModalButton />
        </a>
        <div className="subheader">{formatMessage ({ id: 'FilterTaxationAndPrice.Taxation' })}</div>
        <ul className="slider">
          <DynamicBounds minValue={this.props.startValues.minRangeTaxation} maxValue={this.props.startValues.maxRangeTaxation} startRangeFrom={this.props.startValues.startRangeTaxation} startRantgeTo={this.props.startValues.endRangeTaxation} step={500} parseback={this.parsebackTaxation.bind(this)} />
        </ul>

        <div className="subheader">{formatMessage ({ id: 'FilterTaxationAndPrice.MonthlyPrice' })}</div>
        <ul className="slider">
          <DynamicBounds minValue={this.props.startValues.minRangeMonthly} maxValue={this.props.startValues.maxRangeMonthly} startRangeFrom={this.props.startValues.startRangeMonthly} startRantgeTo={this.props.startValues.endRangeMonthly} step={500} parseback={this.parsebackMonthly.bind(this)} />
        </ul>
      </div>
    );
  }

  closeWindow() {
    this.props.toggleFilterWindow('none');
    if (window.nordania.trackingInitialized) {
      // Tracking
      // Call tracking function from s_code.js that is referenced as external script in the TrackingComponent
      flashEventFunction('Filter: Close Tax and Monthly','','','');
    }
  }
}

FilterLevel03Component.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FilterLevel03Component);
