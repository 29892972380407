'use strict';

import React from 'react';
import Slider from 'rc-slider'
import '../styles/controls/rc-slider/assets/index.scss';
//import '../rc-slider/assets/index.scss';

class DynamicBoundsAnnualMilage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            min: 15000,
            max: 60000,
            step: 5000,
            defaultValue: 15000
        };
    }

    componentWillMount() {
        if (this.props.milage) {
            this.state.defaultValue = this.props.milage;
        }
    }

    render() {
        return <div>
            <Slider
                defaultValue={this.state.defaultValue}
                step={this.state.step}
                min={this.state.min}
                max={this.state.max}
                tipTransitionName="rc-slider-tooltip-zoom-down"
                onAfterChange={this.onSliderChange.bind(this) }
                />
        </div>
    }



    onSliderChange(value) {
        this.props.processMilage(value);
    }
}

DynamicBoundsAnnualMilage.displayName = 'DynamicBoundsAnnualMilage';

export default DynamicBoundsAnnualMilage;
