import React from 'react';
import WhatIsBlue from './WhatIsBlueComponent.js';
import DeliveryTime from './DeliveryTimeComponent.js';
import QandAList from './QandAListComponent.js';
import '../../styles/HelpUniverse.scss';

class HelpUniverseComponent extends React.Component{

    render(){
        return (
            <div className="helpuniverse-content-component">
                <div className="helpuniverse-wrapper">
                    <WhatIsBlue />
                    <DeliveryTime />
                    <QandAList />
                </div>
            </div>
        );
    }
}

export default HelpUniverseComponent;