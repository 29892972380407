// l129 changed 
//require('normalize.css/normalize.css');
import '../styles/App.scss';
//import '../'

import React from 'react';
import Header from './HeaderComponent.js';
import InfoPanel from './InfoPanel.js';
import CookieDisclaimerAndTracking from './CookieDisclaimerAndTrackingComponent.js';
import NotificationFeeChanges from './help/NotificationFeeChanges.js';

const uiStore = {
  listen: function (callback) {
    this.callback = callback;
  },

  showVideo: function (visible) {
    if (this.videoCallback) {
      this.videoCallback.call(null, visible);
    }
  },

  listenVideo: function (callback) {
    this.videoCallback = callback;
  },

  show: function (messageId) {
    if (this.callback) {
      this.callback.setMessageId(messageId);
    }
  },

  showMessageFromUrl: function (url) {
    if (this.callback) {
      this.callback.setMessageUrl(url)
    }
  },

  showHeaderIOSBug: function (state) {
    if (this.headerCallback) {
      this.headerCallback.call(null, state);
    }
  },

  listenHeaderIOSBug: function (callback) {
    this.headerCallback = callback;
  }
};

class AppComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      // C11873210 
      isNotificationShown: true 
      //isNotificationShown: false
    };
  }

  closeNotification() {
    this.setState({ isNotificationShown: true });
  }

  getChildContext() {
    return {
      baseurl: window.nordania.url,
      uiStore
    };
  }
  render() {
    let path = this.props.location.pathname;
    return (
      <div className="index">
        { !this.state.isNotificationShown && (
          <NotificationFeeChanges onClose={() => this.closeNotification()} />
        )}
        <CookieDisclaimerAndTracking />
        <Header currentPath={path} history={this.props.history} />
        {this.props.children}
        <InfoPanel uiStore={uiStore} />
      </div>
    );
  }
}

AppComponent.childContextTypes = {
  baseurl: React.PropTypes.string,
  uiStore: React.PropTypes.object
};

export default AppComponent;
