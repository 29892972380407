// l129 changed 
import React from 'react';
import cn from 'classnames';
import FileSaver from 'file-saver';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import SecondaryButton from '../controls/SecondaryButtonComponent.js';
import DoublePrimaryButton from '../controls/DoublePrimaryButtonComponent.js';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';
import NavLink from '../NavLinkComponent.js';
import axios from 'axios';
import { FormattedNumber, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import { Link } from 'react-router';
import _ from 'lodash';
import ContentCar from './ContentCarComponent.js';
import ContentColours from './ContentColoursComponent.js';
import ContentEquipment from './ContentEquipmentComponent.js';
import OrderReceived from './OrderReceivedComponent.js';
import ContentQuote from './ContentQuoteComponent.js';
import Menu from '../../domain/menu.js';
import FavoriteButton from '../controls/FavoriteButtonComponent.js';
import * as BlueLocalStorage from './../../helpers/localStorageHelper.js';
import * as Recalc from './../../services/RecalculateService.js';
import * as BlueLocalStorageCar from './../../helpers/localStorageCarHelper.js';
import '../../styles/CarOverview.scss';

class CarOverviewComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasNavigated: false,
      showProceedBtn: true,
      carid: this.props.location.query.carid || BlueLocalStorage.getItem('currentCar'),
      nyekm: 15000,
      nyeperiode: 37,
      insurance: 0,
      factoryFittedEquipmentExpense: 0,
      retroFittedEquipmentExpense: 0,
      standard: '',
      thechosenone: null,      
      checkedItems: [],
      checkedReadioButtons: [{
        groupName: 'insurance',
        radioButton: 'lowRisk'
      }, { groupName: 'leasingPeriod', radioButton: 'radioButton36' }],

      milage: 5000,
      showDefaultInuranceSelection: true,
      showLowRiskAsDefault: true,
      selectedColor: 0,
      processedFactoryFittedEquipmentExpenses: 0,
      originalTaxation: 0,
      radioButtonIsNotDefault: false,
      showLegal: false,
      selectedEquipmentList: [],
      customerWishList: [],
      fuelCardList: [],
      chosenInsuranceType: 2,
      powersuboption: 0,      
      insurancePrice: 0,
      udbetaling: 0,
      varnishPrice: 0,
      varnishName: '',
      excess: {}, // was used with insurance
      varnish: {},
      formIsClean: false,
      name: '',
      company: '',
      phone: '',
      email: '',
      mobilePriceArrowClass: 'price-down'
    };
  }

  addToSelectedEquipment(dbId) {
    this.state.selectedEquipmentList.push(dbId);
  }

  togglePowerSubscription(dbid) {    
    this.state.powersuboption = dbid;    
    this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
  }

  addContactInfo(name, company, phone, mail) {
    this.setState({
      name: name,
      company: company,
      phone: phone,
      mail: mail
    })
  }

  removeFromSelectedEquipmentList(dbId) {
    _.remove(this.state.selectedEquipmentList, x => x == dbId);
  }

  GetChosenCarDataWithCache(){
    var cachedCar=BlueLocalStorageCar.getCarFromCache(this.state.carid);
    if(cachedCar && cachedCar.thechosenone){
      this.state=cachedCar;
    }
    else{
      this.GetChosenCarData();
  }
}

GetChosenCarData() {

      var self = this;
      Recalc.GetChosenCarData(this.context.baseurl, this.state.carid, this.state.nyekm, this.state.nyeperiode)
        .then(function (response, err) {
          ''
          if (response) {
            self.state.thechosenone = response.data;
            self.state.milage = response.data.chosenBlue.NyKm;
            self.state.originalTaxation = response.data.chosenCar.Taxation;
            self.state.excess = response.data.excess;
            self.state.varnish = response.data.varnish;
            self.calculateNewMonthlyPrice(self.state.factoryFittedEquipmentExpense, self.state.retroFittedEquipmentExpense);
          } else if (err) {
            alert(err);
          }
        }).catch(function (error) {
          alert(error);
        });
  }

  componentWillMount() {

    const language = (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/-/)[0];
    if (languageWithoutRegionCode == 'da') {
      this.state.language = 'da';
    } else {
      this.state.language = 'en';
    }
    BlueLocalStorageCar.setHistory(this.state.carid);
    this.GetChosenCarDataWithCache();
  }

  toggleAcceptance(removedItem) {
    if (this.state.checkedItems) {
      // if (this.state.checkedItems && this.state.checkedItems.length > 0) {
      _.remove(this.state.checkedItems, x => x == removedItem);

      if (!_.find(this.state.checkedItems, x => x == 'accepInsurance')) {
        _.remove(this.state.checkedReadioButtons, x => x.groupName == 'insurance')
      }

      this.setState({
        checkedItems: this.state.checkedItems,
        checkedReadioButtons: this.state.checkedReadioButtons,
        chosenInsuranceType: 2
      });
      this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
    }
  }

  processMilage(milage) {
    this.state.milage = milage;
    this.state.nyekm = milage;
    this.GetChosenCarData();
  }
  

  processRadioButtons(radioButton, name, radioButtonValue) {
    _.remove(this.state.checkedReadioButtons, x => x.groupName == name);
    // This means that the nyKm has been changed
    if (name == 'leasingPeriod') {
      var newPeriod = parseInt(radioButtonValue);
      this.state.radioButtonIsNotDefault = true;
      this.state.nyeperiode = newPeriod + 1;
      this.GetChosenCarData();
    }

    if (name == 'insurance') {
      if (radioButton == 'highRisk') {
        this.state.chosenInsuranceType = 1;
      } else {
        this.state.chosenInsuranceType = 2;
      }
      this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
    }

    var result = {
      groupName: name,
      radioButton: radioButton
    }

    this.state.checkedReadioButtons.push(result);
    this.setState({
      checkedReadioButtons: this.state.checkedReadioButtons
    });
  }

  escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  sendOrderSummary(isMail, emailaddress, complete) {

    var leasingChoises = []; 
    // is now used only for powersubscription option... it seems

    if (_.find(this.state.checkedItems, x => x == 'assistance')) {
      leasingChoises.push('Nordania Assistance');
    }
    if (_.find(this.state.checkedItems, x => x == 'winterTires')) {
      leasingChoises.push('Vinterdæk');
    }
    if (_.find(this.state.checkedItems, x => x == 'ekstra')) {
      leasingChoises.push('Nordania Ekstra');
    }
    if (_.find(this.state.checkedItems, x => x == 'accepInsurance')) {
      if (this.state.chosenInsuranceType == 1) {
        leasingChoises.push('Forsikring (Høj selvrisiko)');
      } else if (this.state.chosenInsuranceType == 2) {
        leasingChoises.push('Forsikring (lav selvrisiko)');
      }
    }

    if (this.state.powersuboption > 0) {
      leasingChoises.push('pwropt-' + this.state.powersuboption.toString())
    }

    this.state.fuelCardList.forEach(function (element) {
      leasingChoises.push(element)
    }, this);

    var leasingSpecifications = [];

    if (_.find(this.state.checkedItems, x => x == 'assistance')) {
      leasingSpecifications.push('Nordania Assistance');
    }
    if (_.find(this.state.checkedItems, x => x == 'winterTires')) {
      leasingSpecifications.push('Vinterdæk');
    }
    if (_.find(this.state.checkedItems, x => x == 'ekstra')) {
      leasingSpecifications.push('Nordania Ekstra');
    }
    this.state.fuelCardList.forEach(function (element) {
      leasingSpecifications.push(element)
    }, this);

    var filters = {
      carid: this.state.carid,
      customerWishList: this.state.customerWishList,
      nyKm: this.state.nyekm,
      nyeperiode: this.state.nyeperiode,
      afgiftUdstyr: this.state.processedFactoryFittedEquipmentExpenses,
      monthlyPrice: this.state.thechosenone.chosenCar.MonthlyPrice,
      taxation: this.state.thechosenone.chosenCar.Taxation,
      color: this.state.selectedColor,
      taxationMonthly: this.state.thechosenone.taxationMonthly,
      selectedEquipmentList: this.state.selectedEquipmentList,
      extraordinary: this.state.udbetaling,
      mail: emailaddress,
      isMail: isMail,
      fuelCardList: this.state.fuelCardList,
      lang: this.state.language,
      insurance: this.state.insurancePrice,
      nettomoms: this.state.nettomoms,
      momsfradrag: this.state.momsfradrag,
      calculationvalues: this.state.calculationValues,
      completeOrder: complete,
      name: this.state.name,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      leasingChoises: leasingChoises,
      leasingSpecifications: leasingSpecifications,
      name: this.state.name,
      phone: this.state.phone,
      company: this.state.company
    }

    var url = this.context.baseurl + '/api/blue/v1/summary';

    axios.post(url, filters, { responseType: 'arraybuffer', headers: { 'TestTest': 'asd' } })
      .then(function (response, err) {
        if (response) {
          if (!isMail) {
            var blob = new Blob([response.data], { type: 'application/pdf;charset=utf-8' });
            FileSaver.saveAs(blob, 'NordaniaBlue.pdf');
          } else { console.log('Summary sent to email') }
        } else if (err) {
          alert(err);
        }
      }).catch(function (error) {
        alert(error);
      });
  }

  /*recalculate(id, checked) {

    if (checked) {
      this.state.customerWishList.push(id);
    } else {
      _.remove(this.state.customerWishList, x => x == id);
    }
    this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
  }*/

  calculateNewMonthlyPrice(factoryFittedExpense, retroExpense) {

    // assistance, winterTires, ekstra, insurance, noOfFuelCards not in use anymore / they fields are deleted
    // They are used in calculateNewMonthlyPrice, in RecalculateService.js and FavoritesComponent.js too.
    // When I try to delete all of them from these three files (including this one), I got error (404 Not found).

    var assistance = false;
    var winterTires = false;
    var ekstra = false;
    var insurance = 0;
    var noOfFuelCards = this.state.fuelCardList.length;
    if (_.find(this.state.checkedItems, x => x == 'assistance')) {
      assistance = true;
    }
    if (_.find(this.state.checkedItems, x => x == 'winterTires')) {
      winterTires = true;
    }
    if (_.find(this.state.checkedItems, x => x == 'ekstra')) {
      ekstra = true;
    }
    if (_.find(this.state.checkedItems, x => x == 'accepInsurance')) {
      insurance = this.state.chosenInsuranceType;
    }

    

    var self = this;
    Recalc.calculateNewMonthlyPrice(
      //factoryFittedExpense, retroExpense,
      this.state.selectedEquipmentList.join('-'),
      this.context.baseurl,
      this.state.thechosenone.chosenCar.RegType,
      this.state.thechosenone.chosenBlue.ForudsatVarditab,
      this.state.nyeperiode,
      this.state.nyekm,
      this.state.thechosenone.chosenBlue.FraRegafgift,
      this.state.thechosenone.chosenCar.Pv,
      this.state.thechosenone.chosenBlue.Fv,
      this.state.thechosenone.chosenCar.Oblextraeq,
      this.state.thechosenone.chosenBlue.RvSats,
      this.state.originalTaxation,
      this.state.thechosenone.chosenCar.Miljofee,
      this.state.thechosenone.chosenBlue.WheelPris,
      this.state.thechosenone.chosenBlue.Service,
      assistance,
      winterTires,
      ekstra,
      this.state.thechosenone.chosenBlue.WheelWinter,
      this.state.thechosenone.chosenCar.CarFee,
      insurance,
      this.state.thechosenone.chosenBlue.Funding,
      noOfFuelCards,
      this.state.selectedColor,
      this.state.carid,
      this.state.powersuboption
    ).then(function (response, err) {
      if (response) {
        response.data.varnishName = response.data.varnishName.length > 25 ? response.data.varnishName.substr(0, 25) + '...' : response.data.varnishName;
        //Nettomoms is to be hidden from the user
        self.state.nettomoms = response.data.nettomoms;
        self.state.momsfradragsats = response.data.momsfradragsats;
        self.state.fuldafgift = response.data.fuldafgift;
        self.state.momsfradrag = response.data.momsfradrag;
        self.state.momsgrundlag = response.data.momsgrundlag;
        self.state.afgiftEquipment = response.data.afgiftEquipment;
        self.state.thechosenone.chosenCar.MonthlyPrice = response.data.monthlyFeeTotal;
        self.state.thechosenone.chosenCar.Taxation = response.data.taxationtotal;
        self.state.processedFactoryFittedEquipmentExpenses = response.data.priceWithExpenses;
        self.state.thechosenone.taxationMonthly = response.data.taxationMonthly;
        self.state.insurancePrice = response.data.insurancePrice;
        self.state.udbetaling = response.data.udbetaling;
        self.state.calculationValues = response.data.calculationData;
        self.state.varnishPrice = response.data.varnishPrice;
        self.state.varnishName = response.data.varnishName == '' ? '' : ' (' + response.data.varnishName + ')';
        
        // derp way to do it... newbie at react.js and time is limited so its done like so for now...
        localStorage.setItem('VehicleHasPluginRecharge', response.data.hasplugincharge);                

        self.setState({
          thechosenone: self.state.thechosenone,
          processedFactoryFittedEquipmentExpenses: self.state.processedFactoryFittedEquipmentExpenses
        });
        BlueLocalStorageCar.updateCachedCar(self.state.carid,self.state);


        // new...
        BlueLocalStorageCar.togglePowerSubOptionDisplay(self.state.powersuboption);

      } else if (err) {
        alert(err);
      }
    }).catch(function (error) {
      alert(error);
    });
  }


  addToEquipmentExpenses(extraPrice, equipmentType) {
    var extra = parseInt(extraPrice);
    switch (equipmentType) {
      case 'factory':
        this.state.factoryFittedEquipmentExpense += extra;
        this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
        break;
      case 'retro':
        var currentprice = parseInt(this.state.retroFittedEquipmentExpense);
        var total = currentprice + extra;
        this.setState({
          retroFittedEquipmentExpense: String(total)
        });
        this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, total);
        break;
      default:
        break;
    }
  }

  reduceEquipmentExpenses(extraPrice, equipmentType) {

    var extra = parseInt(extraPrice);
    switch (equipmentType) {
      case 'factory':
        this.state.factoryFittedEquipmentExpense -= extra;
        this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
        break;
      case 'retro':
        var currentprice = parseInt(this.state.retroFittedEquipmentExpense);
        var total = currentprice - extra;
        this.setState({
          retroFittedEquipmentExpense: String(total)
        });
        this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, total);
        break;
      default:
        break;
    }
  }

  calculateTotalTilkøb() {
    //var result = parseInt(this.state.processedFactoryFittedEquipmentExpenses) + parseInt(this.state.retroFittedEquipmentExpense) + parseInt(this.state.varnishPrice);
    var result = parseInt(this.state.processedFactoryFittedEquipmentExpenses);

    return <FormattedNumber value={String(result)} />;
  }

  /*isableInsuranceDefaultSelection() {
    this.state.showDefaultInuranceSelection = false;
  }*/

  /*disableShowLowRiskAsDefault() {
    this.state.showLowRiskAsDefault = false;
  }*/

  /*enableShowLowRiskAsDefault() {
    this.state.showLowRiskAsDefault = true;
  }*/

  setSelectedColor(value) {
    this.state.selectedColor = value;
    this.calculateNewMonthlyPrice(this.state.factoryFittedEquipmentExpense, this.state.retroFittedEquipmentExpense);
    this.forceUpdate();
  }

  setStandardEquipment(value) {
    this.state.standard = value;
  }

  closeLegal() {
    this.setState({ showLegal: false });
  }

  toggleLegal() {
    this.context.uiStore.show('LegalInformation');
  }

  componentWillReceiveProps(nextProps) {

    const currentPage = this.props.params.pageName;
    const nextPage = nextProps.params.pageName;

    if (nextPage !== currentPage) {
      this.setState({
        'hasNavigated': true,
        'prevPage': currentPage,
        'animationDirection': Menu.getDirection(currentPage, nextPage),
        'animating': false
      });
    }
    setTimeout(() => this.setState({ 'animating': true }), 10);
  }

  getPageComponent(pageName, config) {
    const pageName2 = pageName || 'car';
    switch (pageName2) {
      case 'car':
        return <ContentCar {...config} />;
      case 'colour':
        return <ContentColours {...config} />;
      case 'equipment':
        return <ContentEquipment {...config} />;
      case 'quote':
        return <ContentQuote ref="checkoutcomp" {...config} />;
      //case 'checkout':
        //return <ContentCheckout ref="checkoutcomp" {...config} />;
      case 'done':
        return <OrderReceived {...config} />;
      default:
        return null;
    }
  }

  showPrice() {
    this.setState({ showPrice: !this.state.showPrice });
    if (this.state.mobilePriceArrowClass === 'price-down') {
      this.setState({mobilePriceArrowClass: 'price-up'});
    }
    else {
      this.setState({mobilePriceArrowClass: 'price-down'});
    }
  }
  closePrice() {
    if (this.state.showPrice === true) {
      this.setState({ showPrice: false });
    }
  }

  checkoutFormStatus(isClean) {
    this.setState({ formIsClean: isClean })
  }

  render() {    
    const { formatMessage, formatNumber } = this.props.intl;
    const hasResult = this.state.thechosenone != null;

    if (!hasResult) {
      // TODO: Show Loading
      return <div></div>;
    }

    const animationClasses = {
      'LEFT': 'content__current-page--in-from-left',
      'RIGHT': 'content__current-page--in-from-right'
    }

    const otherAnimationClasses = {
      'LEFT': 'content__current-page--out-to-right',
      'RIGHT': 'content__current-page--out-to-left'
    }

    const animationClass = animationClasses[this.state.animationDirection] || '';
    const otherAnimationClass = otherAnimationClasses[this.state.animationDirection] || '';

    const config = {
      carData: this.state.thechosenone.chosenCar,
      checkedItems: this.state.checkedItems,
      excess: this.state.excess,
      varnish: this.state.varnish,
      addToEquipmentExpenses: this.addToEquipmentExpenses.bind(this),
      reduceEquipmentExpenses: this.reduceEquipmentExpenses.bind(this),
      toggleAcceptance: this.toggleAcceptance.bind(this), // TODO: delete from everywhere toggleAcceptance, check again if they are really not in use
      processRadioButtons: this.processRadioButtons.bind(this),
      checkedReadioButtons: this.state.checkedReadioButtons,
      milage: this.state.milage,
      processMilage: this.processMilage.bind(this),
      //showDefaultInuranceSelection: this.state.showDefaultInuranceSelection,
      //disableInsuranceDefaultSelection: this.disableInsuranceDefaultSelection.bind(this),
      showLowRiskAsDefault: this.state.showLowRiskAsDefault, // TODO: delete from everywhere LowRisk, HightRisk, insurance, check again if they are really not in use, be careful
      //disableShowLowRiskAsDefault: this.disableShowLowRiskAsDefault.bind(this),
      //enableShowLowRiskAsDefault: this.enableShowLowRiskAsDefault.bind(this),
      selectedColor: this.state.selectedColor,
      setSelectedColor: this.setSelectedColor.bind(this),
      //recalculate: this.recalculate.bind(this),
      sendOrderSummary: this.sendOrderSummary.bind(this),
      setStandardEquipment: this.setStandardEquipment.bind(this),
      addToSelectedEquipment: this.addToSelectedEquipment.bind(this),
      togglePowerSubscription: this.togglePowerSubscription.bind(this),
      removeFromSelectedEquipmentList: this.removeFromSelectedEquipmentList.bind(this),
      //addToFuelCardList: this.addToFuelCardList.bind(this),
      //removeFromFuelCarsList: this.removeFromFuelCarsList.bind(this),
      closeLegal: this.closeLegal.bind(this),
      checkoutFormStatus: this.checkoutFormStatus.bind(this),
      sendComplete: this.sendComplete.bind(this),
      runValidation: this.state.runValidation
    };

    const pageName = this.props.params.pageName;
    const prevPageName = this.state.prevPage;

    const page = this.getPageComponent(pageName, config);
    const prevPage = this.getPageComponent(prevPageName || 'car', config);

    // Don't play animation if there is no previous
    // view.
    let noAnimationClass = '';

    if (!this.state.hasNavigated) {
      noAnimationClass = 'content__current-page--no-animation'
    }

    if (page === '') {
      // eslint-disable-next-line
      console.error('Programming Error: Unknown Page', this.props.params.pageName);
      return null;
    }

    var proceedClass = '';
    if (this.props.pageName == 'quote') {
      proceedClass = 'proceed';
    }
    let varnish = null;
    /*do not display varnish price*/ 
    if (this.state.varnishName != '' && 1 > 2) {
      varnish = <div className="summary-list-item addons-details">
        {formatMessage({ id: 'CarOverview.Colour' })} {this.state.varnishName}<br />
        <span>
          <FormattedNumber value={this.state.varnishPrice} />&nbsp;kr.
          </span>
      </div>
    }

    let summaryClasses = 'caroverview-component';
    let summaryMobile = 'summary';
    if (this.state.showPrice) {
      summaryClasses = 'caroverview-component caroverview-component--shown';
      summaryMobile = 'summary summary-mobile';
    }

    return (

      <div className={summaryClasses}>
        <div className="bg"></div>
        <header>
          <section>
            <div className="title" onClick={this.showPrice.bind(this)}>
              <span><h1>{this.state.thechosenone.chosenCar.Brand} {this.state.thechosenone.chosenCar.Model}</h1><div className={this.state.mobilePriceArrowClass} id='priceDropDown'></div></span>
              <span><p>{this.state.thechosenone.chosenCar.Description1}</p><div className="titlePrice"><FormattedNumber value={this.state.thechosenone.chosenCar.MonthlyPrice} />&nbsp;kr.</div></span>
            </div>
            <div className="controls">
              <div className="container-favorites">
                <FavoriteButton car={this.state} carId={this.state.carid} />
                <p><FormattedMessage id={'SaveToFavorites'} /></p>
              </div>
              <Link to={this.props.lastcalled}>
                <CloseModalButton />
              </Link>
            </div>
          </section>
        </header>

        <section>
          <div className="car-content">
            <div key={prevPageName || 'initial'} className={cn('content__other-page', otherAnimationClass, noAnimationClass)}>
              <div className="content__other-page-cover"></div>
              {prevPage}
            </div>
            <div key={pageName} className={cn('content__current-page', animationClass, noAnimationClass)}>
              {page}
            </div>
          </div>

          <section className={summaryMobile}>

            <div className="phantom"></div>

            <section>

              <div className="summary-list-item spacing">
                {formatMessage({ id: 'CarOverview.TaxationTotal' })}
                <em>
                  <FormattedNumber value={this.state.thechosenone.chosenCar.Taxation} />&nbsp;kr.
                </em>
              </div>

              <div className="summary-list-item spacing">
                <div className="small-text-container">{formatMessage({ id: 'CarOverview.TaxationMonthly' })}&nbsp;<span className="small-text">{formatMessage({ id: 'CarOverview.EnvironmentalExtraCharge' })}</span></div>
                <em>
                  <FormattedNumber value={this.state.thechosenone.taxationMonthly} />&nbsp;kr.
                </em>
              </div>

              <div className="summary-list-item spacing">
                {formatMessage({ id: 'CarOverview.MonthlyFee' })}
                <em>
                  <FormattedNumber value={this.state.thechosenone.chosenCar.MonthlyPrice} />&nbsp;kr.
                </em>
              </div>
              <hr />
              <div className="summary-list-item spacing promo-text highlighted">
                <span>
                {formatMessage({ id: 'DisclaimerCar' })} <div style={{display:'inline-block'}}><NavLink to="/help">{formatMessage({ id: 'Frontpage.Button' })}</NavLink></div>
                </span>
              </div>
              <div className="summary-list-item addons-details hidden">
                {formatMessage({ id: 'CarOverview.FactoryFittedEquipment' })}<br />
                <span>
                  <FormattedNumber value={this.state.processedFactoryFittedEquipmentExpenses} />&nbsp;kr.
                </span>
              </div>
              <div className="summary-list-item addons-details hidden">
                {formatMessage({ id: 'CarOverview.RetroFittedEquipment' })}<br />
                <span>
                  <FormattedNumber value={this.state.retroFittedEquipmentExpense} />&nbsp;kr.
                </span>
              </div>
              {varnish}
              <div className="summary-list-item addons-details highlight hidden spacing">
                {formatMessage({ id: 'CarOverview.ExtrasTotal' })}<br />
                <span>{this.calculateTotalTilkøb()}&nbsp;kr.</span>
              </div>
              {/*
                <span>
                  <FormattedNumber value={this.state.insurancePrice} />&nbsp;kr.
                </span>
              </div>
              */}

              <div className="summary-list-item">

                {formatMessage({ id: 'CarOverview.ExtraordinaryLeasePaymentFirstLine' })}<br />{formatMessage({ id: 'CarOverview.ExtraordinaryLeasePaymentSecondLine' })}
                <div>{formatNumber(this.state.udbetaling)}&nbsp;kr.</div>
              </div>
              <p className="spacing dimmed">{formatMessage({ id: 'CarOverview.ExtraordinaryLeasePaymentNote' })}</p>
              {/*<p className="spacing">{this.state.thechosenone.extraordinary * 100}% {formatMessage({ id: 'CarOverview.ExtraordinaryLeasePaymentNote' })}</p>*/}
              <a className="legal" onClick={() => { this.toggleLegal() }}>{formatMessage({ id: 'LegalInformationButtonText' })}</a>

            </section>
            <section className="buttons buttons-inline">
            </section>
          </section>
        </section>

        <footer className="car-overview__footer">
          <section className="footer">

            <section className={proceedClass + ' tab-menu'}>
              {Menu.menuItems.map(item => <NavLink closePrice={this.closePrice.bind(this)} key={item.pageName} to={item.href} className={proceedClass} label={item}></NavLink>)}
            </section>

            <section className="buttons">
              {this.renderProceedNavLink()}
              {this.renderSaveToPdfAndSaveToEmail()}
              {/*this.props.params.pageName !== 'quote' && <SecondaryButton id='saveForLaterButton'
                hideProceedBtn={this.hideProceedBtn.bind(this)}
                showProceedBtn={this.showProceedBtn.bind(this)}
            text={formatMessage({ id: 'CarOverview.SaveForLater' })} />*/}
              {this.props.params.pageName === 'quote' && <SecondaryButton id='saveForLaterButton'
                hideProceedBtn={this.hideProceedBtn.bind(this)}
                showProceedBtn={this.showProceedBtn.bind(this)}
                text={formatMessage({ id: 'CarOverview.SaveForLater' })} />}
            </section>
          </section>
        </footer>
      </div>
    );
  }

  renderProceedNavLink() {
    const { formatMessage } = this.props.intl;

    if (this.props.params.pageName !== 'quote') {
      if (this.props.params.pageName === undefined || this.props.params.pageName === 'car') {
        // Car pane - navigate to colour
        return <NavLink to="/details/colour">
                <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
              </NavLink>;
      } else if (this.props.params.pageName === 'colour') {
        // Color pane - navigate to equipment
        let buttonDisabled = this.state.selectedColor === 0;
        if (buttonDisabled) {
          return <div onClick={this.validateChildren.bind(this)}>
          <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} disabled="true" /></div>
        } else {
          return <div onClick={this.endValidateChildren.bind(this)}>
                  <NavLink to="/details/equipment">
                    <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
                  </NavLink>
                </div>;
        }
      } else if (this.props.params.pageName === 'equipment') {
        // Equipment pane - navigate to quote
        return <NavLink to="/details/quote">
                <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
              </NavLink>;
      } //else if (this.state.showProceedBtn) {
        //return <NavLink to="/details/checkout">
          //<PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
        //</NavLink>;
      //}
    } else {
      if (this.state.formIsClean) {
        return <div onClick={this.sendComplete.bind(this)}>
          <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.RequestOffer' })} /></div>
      } else {
        return <div onClick={this.validateChildren.bind(this)}>
          <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.RequestOffer' })} disabled="true" /></div>
      }
    }
  }
  validateChildren(){
    this.setState({runValidation: true});
  }

  endValidateChildren(){
    this.setState({runValidation: false});
  }

  sendComplete() {
    let instance = this.refs.checkoutcomp.getWrappedInstance();
    let formstate = instance.state.form;
    this.state.name = formstate.name;
    this.state.phone = formstate.phone;
    this.state.company = formstate.company;
    this.state.email = formstate.email;
    this.sendOrderSummary(true, this.state.email, true);
    this.context.router.push('/done')
  }

  renderSaveToPdfAndSaveToEmail() {
    if (!this.state.showProceedBtn) {
      return <DoublePrimaryButton sendOrderSummary={this.sendOrderSummary.bind(this)} />;
    }
  }

  showProceedBtn() {
    this.setState({ showProceedBtn: true });
  }

  hideProceedBtn() {
    this.setState({ showProceedBtn: false });
  }
}

CarOverviewComponent.displayName = 'CarOverviewComponent';

CarOverviewComponent.propTypes = {
  intl: intlShape.isRequired
};

CarOverviewComponent.contextTypes = {
  baseurl: React.PropTypes.string,
  router: React.PropTypes.object.isRequired,
  uiStore: React.PropTypes.object.isRequired
};

export default injectIntl(CarOverviewComponent);