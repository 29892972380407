'use strict';

import React from 'react';
import '../../styles/controls/CloseModalButton.scss';

class CloseModalButtonComponent extends React.Component {
  render() {
    return (
        <div className="closemodalbutton-component">
          <svg className="icn-close" width="15" height="15" viewBox="0 0 15 15">
            <path fill="#003755" fillRule="evenodd" d="M14.79955,1.16485989 C15.0668167,0.897593237 15.0668167,0.466693939 14.79955,0.199427286 C14.533647,-0.0664757619 14.1027477,-0.0664757619 13.835481,0.199427286 L7.50017045,6.53610145 L1.16349628,0.199427286 C0.897593237,-0.0664757619 0.466693939,-0.0664757619 0.199427286,0.199427286 C-0.0664757619,0.466693939 -0.0664757619,0.897593237 0.199427286,1.16485989 L6.53610145,7.50017045 L0.199427286,13.835481 C-0.0664757619,14.1027477 -0.0664757619,14.533647 0.199427286,14.8009136 C0.333060612,14.9331833 0.5076021,15 0.682143588,15 C0.856685075,15 1.03122656,14.9331833 1.16349628,14.8009136 L7.50017045,8.46423945 L13.835481,14.8009136 C13.9691143,14.9331833 14.1436558,15 14.3181973,15 C14.4927388,15 14.6672803,14.9331833 14.79955,14.8009136 C15.0668167,14.533647 15.0668167,14.1027477 14.79955,13.835481 L8.46423945,7.50017045 L14.79955,1.16485989 Z" />
          </svg>
        </div>

    );
  }
}

CloseModalButtonComponent.displayName = 'ControlsCloseModalButtonComponent';

// Uncomment properties you need
// CloseModalButtonComponent.propTypes = {};
// CloseModalButtonComponent.defaultProps = {};

export default CloseModalButtonComponent;
