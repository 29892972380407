import React from 'react';
import { injectIntl } from 'react-intl';
import CloseModalButton from '../controls/CloseModalButtonComponent.js';

class NordaniaBlueIntroVideo extends React.Component{
    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="showroom-video" >
                <div className="showroom-video__window">
                    <a href="#" className="showroom-video__close" onClick={() => this.props.closeVideo()}>
                        <CloseModalButton />
                    </a>
                    <div className="showroom-video__title">
                        <div className="header-container">
                        <h1>{formatMessage({ id: 'Showroom.VideoTitle' })}</h1>
                        </div>
                    </div>
                    <div className="showroom-video__description">
                        {formatMessage({ id: 'Showroom.VideoDescription' })}
                    </div>
                    <div className="showroom-video__video">
                        <video width="100%" controls autoPlay>
                        <source src="assets/nordania_blue.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        );
    }
}
export default injectIntl(NordaniaBlueIntroVideo);