
'use strict';
import axios from 'axios';
import React from 'react';
import CarCard from './car-card/CarCardComponent.js';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import '../styles/ContentSection.scss';



class ContentSectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: []
    };
  }

  componentDidMount() {
    var self = this;
    axios(this.context.baseurl + '/api/blue/v1/GetCategoryCars',
      {
        params: {
          type: this.props.type,
          limit: this.props.limit
        }
      })
      .then(function (response) {
        self.setState({ cars: response.data })
      })
      .catch(function () {
      });
  }



  render() {
    return (
      <section className="contentsection-component">
        <header>
          <h2>{this.props.title}</h2>
          <Link to={'/category/' + this.props.type}>
            <FormattedMessage id={'SontentSection.ShowMore'} />
          </Link>
        </header>
        <section>
          {
            this.state.cars.map((car, i) => {
              return <CarCard key={i} car={car} />

            })
          }
        </section>
      </section>
    );
  }
}

ContentSectionComponent.contextTypes = {
  baseurl: React.PropTypes.string
};
export default ContentSectionComponent;
