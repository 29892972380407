'use strict';

import React from 'react';
import { Link } from 'react-router';

import CarCardFavorites from '../car-card/CarCardFavoritesComponent.js';
import CarListFavorites from './CarListFavoritesComponent.js';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import NavChevron from '../controls/NavChevronComponent.js';
import { injectIntl, intlShape } from 'react-intl';
import * as BlueLocalStorage from './../../helpers/localStorageHelper.js';
import * as Recalc from './../../services/RecalculateService.js';
import { sort, filterOptions } from './../../helpers/sortHelper.js';
import '../../styles/Favorites.scss';
import img from '../../images/illu_loading_card.svg';
import loading from '../../images/ring-alt.gif';
class FavoritesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      state: 'LOADING',
      show: 'grid',
      orderBy:'Taxation',
      direction:' '
    };
  }
componentWillMount(){
    this.props.doSomething('favorites');

}

componentDidMount(){
  this.getData(this.props.route.favorites,this.props.route.history);
}

changeOrder(event){
  this.setState({orderBy: event.target.value, direction:' '});
}
changeDirection(){
  this.setState({direction: this.state.direction=='asc'?'desc':'asc'});
}


componentWillReceiveProps(nextProps) {
  if(this.props.route.favorites!=nextProps.route.favorites||this.props.route.history!=nextProps.route.history){
    this.showGrid();
    this.getData(nextProps.route.favorites,nextProps.route.history);
  }
}

getData(fav, history) {
  var favorites =  [];
  var favoritesObject = [];

  var self = this;
  var favoritesToGet =[];
  const {formatMessage} = this.props.intl;
  var errormessage = formatMessage({ id: 'ErrorMessageBadData' });
  if(fav){
    favorites = JSON.parse(BlueLocalStorage.getItem('favorites')) || [];
    favoritesObject = JSON.parse(BlueLocalStorage.getItem('favoritesObj2')) || [];
    var favoritesObjectIds =[];
    if(favoritesObject){
      favoritesObjectIds = favoritesObject.map(a => a.carid);
    }
    favorites.forEach(element => {
    const objectMissing = (favoritesObjectIds.indexOf(element) === -1);
    if(objectMissing){
      favoritesToGet.push(element);
    }
  });
}
else if(history){
  favoritesToGet = JSON.parse(BlueLocalStorage.getItem('history')) || [];
}
  self.setState({

            state: favoritesObject.length > 0 || favoritesToGet.length>0 ? 'RESULTS' : 'NO_RESULTS',
            cars: favoritesObject
          });

    favoritesToGet.forEach(element=>{
      Recalc.GetChosenCarData(self.context.baseurl, element, 15000, 37)
      .then(function (response, err) {
        ''
        if (response) {
          var newCar={};
          newCar.thechosenone = response.data;
          newCar.milage = response.data.chosenBlue.NyKm;
          newCar.originalTaxation = response.data.chosenCar.Taxation;
          newCar.excess = response.data.excess;
          newCar.varnish = response.data.varnish;
          Recalc.calculateNewMonthlyPrice('',
            self.context.baseurl,
            newCar.thechosenone.chosenCar.RegType,
            newCar.thechosenone.chosenBlue.ForudsatVarditab,
            37,
            15000,
            newCar.thechosenone.chosenBlue.FraRegafgift,
            newCar.thechosenone.chosenCar.Pv,
            newCar.thechosenone.chosenBlue.Fv,
            newCar.thechosenone.chosenCar.Oblextraeq,
            newCar.thechosenone.chosenBlue.RvSats,
            newCar.originalTaxation,
            newCar.thechosenone.chosenCar.Miljofee,
            newCar.thechosenone.chosenBlue.WheelPris,
            newCar.thechosenone.chosenBlue.Service,
            false,
            false,
            false,
            newCar.thechosenone.chosenBlue.WheelWinter,
            newCar.thechosenone.chosenCar.CarFee,
            0,
            newCar.thechosenone.chosenBlue.Funding,
            0,
            0,
            element
          ).then(function (resp, err) {
                if (resp) {
                  resp.data.varnishName = resp.data.varnishName.length > 25 ? resp.data.varnishName.substr(0, 25) + '...' : resp.data.varnishName;
                  //Nettomoms is to be hidden from the user
                  newCar.nettomoms = resp.data.nettomoms;
                  newCar.momsfradragsats = resp.data.momsfradragsats;
                  newCar.fuldafgift = resp.data.fuldafgift;
                  newCar.momsfradrag = resp.data.momsfradrag;
                  newCar.momsgrundlag = resp.data.momsgrundlag;
                  newCar.afgiftEquipment = resp.data.afgiftEquipment;


                  newCar.thechosenone.chosenCar.MonthlyPrice = resp.data.monthlyFeeTotal;
                  newCar.thechosenone.chosenCar.Taxation = resp.data.taxationtotal;
                  newCar.processedFactoryFittedEquipmentExpenses = resp.data.priceWithExpenses;
                  newCar.thechosenone.taxationMonthly = resp.data.taxationMonthly;
                  newCar.insurancePrice = resp.data.insurancePrice;
                  newCar.udbetaling = resp.data.udbetaling;
                  newCar.calculationValues = resp.data.calculationData;
                  newCar.varnishPrice = resp.data.varnishPrice;
                  newCar.varnishName = resp.data.varnishName == '' ? '' : ' (' + resp.data.varnishName + ')';
                  favoritesObject.push(newCar);

                   self.setState({
                           //  state: favoritesObject.length > 0 ? 'RESULTS' : 'NO_RESULTS',
                             cars: favoritesObject
                           });
                 }
                 else if (err) {
                  alert(errormessage);
                }
              }).catch(function () {
                if(fav){
                  var index = favorites.indexOf(element);
                  favorites.splice(index, 1);
                  BlueLocalStorage.setItem('favorites', JSON.stringify(favorites));
                  }
                    else if(history){
                      var index = favoritesToGet.indexOf(element);
                      favoritesToGet.splice(index, 1);
                      BlueLocalStorage.setItem('history', JSON.stringify(favoritesToGet));
                    }
                alert(errormessage);
              })

         // self.calculateNewMonthlyPrice(factoryFittedExpense, retroExpense);
        } else if (err) {

          alert(errormessage);
        }
      }).catch(function () {
        if(fav){
          var index = favorites.indexOf(element);
          favorites.splice(index, 1);
            BlueLocalStorage.setItem('favorites', JSON.stringify(favorites));
            }
            else if(history){
              var index = favoritesToGet.indexOf(element);
              favoritesToGet.splice(index, 1);
              BlueLocalStorage.setItem('history', JSON.stringify(favoritesToGet));
            }
            alert(errormessage);
          });



    });
   }


  render() {    
    let content;    
    const {formatMessage} = this.props.intl;
    let headerText=this.props.route.history ? formatMessage({ id: 'LastViewed' }):this.props.route.favorites?formatMessage({ id: 'Favorites.Header' }):'';
    let cars =[];
    this.state.cars.map((car, i) => {
      var newCar={};
      newCar.chosenCar=car.thechosenone.chosenCar;
      newCar.Taxation=car.thechosenone.chosenCar.Taxation;
      newCar.Consumption= car.thechosenone.chosenCar.Consumption;
      newCar.Emissions= car.thechosenone.chosenCar.Emissions;
      newCar.CdpBodyType= car.thechosenone.chosenCar.CdpBodyType;
      newCar.Transmission= car.thechosenone.chosenCar.Transmission;
      newCar.Hk=car.thechosenone.chosenCar.Hk;
      newCar.MonthlyPrice= car.thechosenone.chosenCar.MonthlyPrice;
      newCar.udbetaling= car.udbetaling;

      cars.push(newCar);
    })

    sort(cars, this.state.direction, this.state.orderBy, (result)=>{
      cars=result;
    })

    if (this.state.state == 'NO_RESULTS') {
      content = <section className="no-favorites">
        <p>
        {this.props.route.history ? formatMessage({ id: 'Favorites.NoHistoryFirstLine' }):this.props.route.favorites?formatMessage({ id: 'Favorites.NoFavoritesFirstLine' }):''}<br />
        {this.props.route.history ? formatMessage({ id: 'Favorites.NoHistorySecondLine' }):this.props.route.favorites?formatMessage({ id: 'Favorites.NoFavoritesSecondLine' }):''}
        </p>
        <Link to="/">

          <PrimaryButton text={formatMessage({ id: 'Back' })} />
        </Link>

        <div className="loading-cards">
          <img src={img} width="381px" />
          <img className="middle" src={img} width="381px" />
          <img src={img} width="381px" />
        </div>
      </section>
    } else if (this.state.state == 'LOADING') {
      <div className="loading"><img src={loading} alt="loading" /></div>


    } else if (this.state.state === 'RESULTS') {

      if (this.state.show == 'grid') {
        content =
        <div>
        <div style={{display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'}}>
        <h2 style={{position: 'relative'}}>
        <select  onChange={this.changeOrder.bind(this)} value={this.state.orderBy}>
        {filterOptions(formatMessage).map((option,i)=>
        <option value={option.id} key={i}>{option.title}</option> )
        }
        </select>
        <div className={'sortBtn '+this.state.direction} onClick={this.changeDirection.bind(this)}></div>
        </h2>
        </div>
        <section className="grid-view"> {
          cars.map((car, i) => {
            return <CarCardFavorites key={i} car={car} isFavorite={true} />
          })
        } </section>
        </div>
      } else {
        content = <section className="list-view">
          <CarListFavorites cars={this.state.cars} />
        </section>
      }
    }

    return (
      <div className="favorites-component">
        <header className="header-area">
          <div>
            <Link to="/">
              <NavChevron />
            </Link>
          </div>
          <h1>{headerText}</h1>
          <div>
            <div className="hit-area" onClick={() => { this.showGrid() } }>
              <svg className="icn-grid-view" width="15" height="15" viewBox="0 0 15 15" fill="#7F9BAA" fillRule="evenodd" transform="translate(1 1)">
                <g>
                  <rect width="4" height="4" />
                  <rect width="4" height="4" x="9" />
                  <rect width="4" height="4" x="9" y="9" />
                  <rect width="4" height="4" y="9" />
                </g>
              </svg>
            </div>
            {/*<div className="icn-grid-view" onClick={() => { this.showGrid() } }> </div>*/}
            <div className="hit-area" onClick={() => { this.showList() } }>
              <svg className="icn-list-view" width="15" height="15" viewBox="0 0 15 15" fill="#7F9BAA" fillRule="evenodd">
                <g >
                  <path d="M13.5 2C14.0522847 2 14.5 1.55228475 14.5 1 14.5.44771525 14.0522847 0 13.5 0L1.5 0C.94771525 0 .5.44771525.5 1 .5 1.55228475.94771525 2 1.5 2L13.5 2zM13.5 8C14.0522847 8 14.5 7.55228475 14.5 7 14.5 6.44771525 14.0522847 6 13.5 6L1.5 6C.94771525 6 .5 6.44771525.5 7 .5 7.55228475.94771525 8 1.5 8L13.5 8zM13.5 14C14.0522847 14 14.5 13.5522847 14.5 13 14.5 12.4477153 14.0522847 12 13.5 12L1.5 12C.94771525 12 .5 12.4477153.5 13 .5 13.5522847.94771525 14 1.5 14L13.5 14z" />
                </g>
              </svg>
            </div>
            {/*<div className="icn-list-view" onClick={() => { this.showList() } }> </div>*/}
            {/*<CloseModalButton />*/}
          </div>
        </header>
        {content}
      </div>
    );
  }

  showGrid() {
    this.setState({
      show: 'grid'
    });
  }
  showList() {
    this.setState({
      show: 'list'
    });
  }

}

FavoritesComponent.displayName = 'FavoritesComponent';

// Uncomment properties you need
// FavoritesComponent.defaultProps={};
FavoritesComponent.contextTypes = {
  baseurl: React.PropTypes.string
};

FavoritesComponent.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(FavoritesComponent);
