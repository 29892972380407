import React from 'react';
import { injectIntl } from 'react-intl';
import '../../styles/DeliveryTime.scss';

class DeliveryTime extends React.Component{
    render() {
        const {formatMessage} = this.props.intl;
        
        return(
            <div className="delivery-time-container">
                <h1>{formatMessage({id:'DeliveryTime.Headline'})}</h1>
                <p>{formatMessage({id:'DeliveryTime.Text'})}</p>
            </div>
        );
    }
}
export default injectIntl(DeliveryTime);