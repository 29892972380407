'use strict';

import React from 'react';
import '../../styles/controls/PrimaryButton.scss';

class PrimaryButtonComponent extends React.Component {
  render() {
    return (
      <div className={'primarybutton-component ' + ((this.props.disabled === 'true' || this.props.disabled === true)   ? 'disabledButton' : '')}>

        {this.props.text}
      </div>
    );
  }
}

export default PrimaryButtonComponent;
