'use strict';

import React from 'react';
import Checkbox from '../controls/CheckboxComponent.js';
import axios from 'axios'
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import NavLink from '../NavLinkComponent.js';
import PrimaryButton from '../controls/PrimaryButtonComponent.js';
import '../../styles/ContentEquipment.scss';

class ContentEquipmentComponent extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      eftermonteret: null,
      fabriksmonteret: null,
      standard: null,
      showFactoryEquipment: true,
      showRetroEquiment: true
    }
  }

  componentWillMount() {
    const language = (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;

    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/-/)[0];
    if (languageWithoutRegionCode == 'da') {
      this.state.language = 'da';
    } else {
      this.state.language = 'en';
    }

    if (this.props.carData) {

      var url = this.context.baseurl + '/api/blue/v1/GetEquipment?carId=' + this.props.carData.CarlistId;
      var self = this;
      var config = {
        url: url,
        method: 'get',
        headers: {
        }
      };

      axios.request(config)
        .then(function (response, err) {
          if (response) {
            self.setState({
              eftermonteret: response.data.eftermonteret,
              fabriksmonteret: response.data.fabriksmonteret,
              standard: response.data.standard
            });
          } else if (err) {
            alert(err);
          }
        }).catch(function (error) {
          alert(error);
        });
    }
  }

  renderStandardEquipment() {
    var self = this;
    if (this.state && this.state.standard) {
      var result = '';
      var equipments = this.state.standard.equipments;

      result = equipments.map(function (elem) {
        return self.state.language == 'en' ? elem.EnglishName : elem.DanishName;
      }).join(', ');

      this.props.setStandardEquipment(result);
      return <p>{result}</p>;
    }
  }

  /**
   * This function takes care of the checke boxes that should be aligned on the right side of a section
   */
  renderList(data, equipmentType) {
    var self = this;

    if (data) {
      const items = _.map(data.equipments.slice(0, 1003), (equipment, index) => {

        var label = self.state.language == 'en' ? equipment.EnglishName : equipment.DanishName

        return <Checkbox
          key={index}
          {...equipment}
          checkedItems={this.props.checkedItems}
          checkBoxText={label}
          id={equipment.Id}
          databaseId={equipment.Id}
          equipmentType={equipmentType}
          addToEquipmentExpenses={this.props.addToEquipmentExpenses}
          reduceEquipmentExpenses={this.props.reduceEquipmentExpenses}
          addToSelectedEquipment={this.props.addToSelectedEquipment}
          removeFromSelectedEquipmentList={this.props.removeFromSelectedEquipmentList}
          />
        }
      );
      return items;
    }
  }


  componentDidMount() {
    var efterMonteretArrow = document.getElementById('retroMont');

    var self = this;
    efterMonteretArrow.onclick = function (event) {
      event.stopPropagation();

      var name = efterMonteretArrow.className;
      if (name == 'chevron-up') {
        efterMonteretArrow.className = 'chevron-down';
        self.setState({
          showRetroEquiment: false
        });
      } else {
        efterMonteretArrow.className = 'chevron-up';
        self.setState({
          showRetroEquiment: true
        });
      }
    };

    var fabrikMonteretArrow = document.getElementById('factoryMont');

    // Factory Mounted equipment
    fabrikMonteretArrow.onclick = function (event) {
      event.stopPropagation();
      var name = fabrikMonteretArrow.className;
      if (name == 'chevron-up') {
        fabrikMonteretArrow.className = 'chevron-down';
        self.setState({
          showFactoryEquipment: false
        });
      } else {
        fabrikMonteretArrow.className = 'chevron-up';
        self.setState({
          showFactoryEquipment: true
        });
      }
    };
  }

  renderFactoryFittedSection() {
    if (this.state.showFactoryEquipment) {
      return <section className="checkbox-container">
        {this.renderList(this.state.fabriksmonteret, 'factory')}
      </section>
    }
  }

  renderRetroFittedSection() {
    if (this.state.showRetroEquiment) {
      return <section className="checkbox-container">
        {this.renderList(this.state.eftermonteret, 'retro')}
      </section>
    }
  }

  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div className="contentequipment-component">
        <div className="phantom"></div>
        <section>
          <h3>{formatMessage({ id: 'ContentEquipment.StandardEquipment' })}</h3>
          {this.renderStandardEquipment()}
          <h2>{formatMessage({ id: 'ContentEquipment.MoreEquipment' })}</h2>
          <section className="dropdown">
            <div>
              <h3>{formatMessage({ id: 'ContentEquipment.FactoryFittedEquipment' })}</h3>
              <span className='chevron-up' id='factoryMont'></span>
            </div>
            <hr />
          </section>

          {this.renderFactoryFittedSection()}

          <section className="dropdown">
            <div>
              <h3>{formatMessage({ id: 'ContentEquipment.RetroFittedEquipment' })}</h3>
              <span className='chevron-up' id='retroMont'></span>
            </div>
            <hr />
          </section>
          {this.renderRetroFittedSection()}
        </section>

        <section className="buttons buttons-inline small-screen-button">
         {<NavLink to="/details/quote">
            <PrimaryButton id='proceedButton' text={formatMessage({ id: 'CarOverview.Next' })} />
          </NavLink>}
        </section>
      </div>
    );
  }
}

ContentEquipmentComponent.propTypes = {
  intl: intlShape.isRequired
};

ContentEquipmentComponent.contextTypes = {
  baseurl: React.PropTypes.string
};

export default injectIntl(ContentEquipmentComponent);
