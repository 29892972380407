'use strict';

import React from 'react';
import { Link } from 'react-router';

import SearchField from './controls/SearchFieldComponent.js'
import { injectIntl } from 'react-intl';
//import img from '../images/logo.svg';
// import LoginButton from './controls/LoginButtonComponent'
import '../styles/Header.scss';

class HeaderComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hideHeaderIOSBug: false,
      width: window.innerWidth
    }
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  iOSBug(state) {
    if (!state) {
      this.setState({hideHeaderIOSBug: true});
    } else {
      this.setState({hideHeaderIOSBug: false});
    }
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
  
  updateWindowWidth() {
    // This function is needed when burger menu was opened and screen was enlarged.
    // In this scenario toggleBurgerMenu leaves "leftovers" which is removed here.

    this.setState({ width: window.innerWidth});

    let linksEl = document.querySelector('.header-navigation-mobile');
    let headerEl = document.querySelector('.header-content-mobile');
    let contentEl = this.props.currentPath.indexOf('help') == -1 ? // if not help content, else help. Help is not under main content
      contentEl = document.querySelector('.content-component') : contentEl = document.querySelector('.helpuniverse-content-component');
    if (window.innerWidth > 350 && contentEl.style.display === 'none'){ // fix leftovers
      linksEl.style.display = 'none';
      contentEl.style.display = 'flex';
      headerEl.style.marginTop = '0px';
    }
  }

  toggleBurgerMenu(){
    let linksEl = document.querySelector('.header-navigation-mobile');
    let headerEl = document.querySelector('.header-content-mobile');
    let contentEl = this.props.currentPath.indexOf('help') == -1 ? // if not help content, else help. Help is not under main content
      contentEl = document.querySelector('.content-component') : contentEl = document.querySelector('.helpuniverse-content-component');
    if (linksEl.style.display === 'block') { // hide burger menu options
      linksEl.style.display = 'none';
      contentEl.style.display = 'flex';
      headerEl.style.marginTop = '0px';
    } else {                                // display burger menu options
      linksEl.style.display = 'block';
      contentEl.style.display = 'none';
      headerEl.style.marginTop = '187px'; // jumps up without margin top
    }
  }

  render() {
    const {formatMessage} = this.props.intl;    
    const img = '../images/logo.svg';
    this.context.uiStore.listenHeaderIOSBug(this.iOSBug.bind(this));
    let helpUniverse = this.props.currentPath.indexOf('help') !== -1;
    let favorites = this.props.currentPath.indexOf('favorites') !== -1;
    let history = this.props.currentPath.indexOf('history') !== -1;
    return (
      <header className={'header-component ' + (this.state.hideHeaderIOSBug ? 'hideiosbug' : '')}>
        <div className="header-content">
          <div className="wrapper">
            <Link className="logo" to=""><img src={"https://nordania.dk/-/media/nordania-extranets/app/images/nordania-leasing-logo.svg"} alt="Nordania Leasing" /></Link>
            <div className="group">
              {!helpUniverse && <SearchField visible={false} />}
            </div>
          </div>
          <div className="header-navigation">
            {(helpUniverse || favorites || history) && <Link to="/">{formatMessage({id:'Frontpage'})}</Link>}
            {!(helpUniverse || favorites || history) && <Link className="active" to="/">{formatMessage({id:'Frontpage'})}</Link>}

            {!helpUniverse && <Link to="help">{formatMessage({id:'Help'})}</Link>}
            {helpUniverse && <Link className="active" to="help">{formatMessage({id:'Help'})}</Link>}

            {!favorites && <Link className="favorites-button" to="favorites">{formatMessage({id:'Showroom.MyFavorites'})}</Link>}
            {favorites && <Link className="favorites-button active" to="favorites">{formatMessage({id:'Showroom.MyFavorites'})}</Link>}

            {!history && <Link to="history">{formatMessage({id:'LastViewed'})}</Link>}
            {history && <Link className="active" to="history">{formatMessage({id:'LastViewed'})}</Link>}
          </div>
        </div>

        <div className="header-content-mobile">
          <div className="wrapper">
            <Link className="logo" to="/"><img src={img} alt="Nordania Leasing" /></Link>
            <div className="group">
              {!helpUniverse && <SearchField visible={false} />}
            </div>
            <div className="burger-menu">
              <button type="button" className="burger-menu-button" onClick={() => this.toggleBurgerMenu()}>
                <span className="title">Menu</span>
              </button>
            </div>
          </div>
          <div className="header-navigation-mobile">
            {(helpUniverse || favorites || history) && <Link to="/" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'Frontpage'})}</Link>}
            {!(helpUniverse || favorites || history) && <Link className="active" to="/" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'Frontpage'})}</Link>}

            {!helpUniverse && <Link to="help" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'Help'})}</Link>}
            {helpUniverse && <Link className="active" to="help" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'Help'})}</Link>}

            {!favorites && <Link className="favorites-button" to="favorites" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'Showroom.MyFavorites'})}</Link>}
            {favorites && <Link className="favorites-button active" to="favorites" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'Showroom.MyFavorites'})}</Link>}

            {!history && <Link to="history" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'LastViewed'})}</Link>}
            {history && <Link className="active" to="history" onClick={() => this.toggleBurgerMenu()}>{formatMessage({id:'LastViewed'})}</Link>}
          </div>
        </div>
      </header>
    );
  }
}
HeaderComponent.contextTypes = {
  uiStore: React.PropTypes.object
}

export default injectIntl(HeaderComponent);
